import { Form, Formik } from 'formik'

import { toast } from 'react-toastify'
import * as Yup from 'yup'
import FormikAutocomplete from '../../components/Formik/FormikAutocomplete'
import FormikTextField from '../../components/Formik/FormikTextField'
import LoadingButton from '../../components/LoadingButton'
import useNutrientQuery from '../../query/nutrient'

function NutrientForm({ id, onSuccess }) {
  const { getNutrient, createNutrient, updateNutrient } = useNutrientQuery()
  const { data } = getNutrient({ id })
  const { mutate: update } = updateNutrient()
  const { mutate: create } = createNutrient()

  const initialValues = {
    name: data?.data?.name || '',
    tags: data?.data?.tags || '',
    unit: data?.data?.unit || 'mg',
    key: data?.data?.key || ''
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required')
  })

  const onSubmit = async (values, { setErrors }) => {
    try {
      if (id) {
        update({ id, data: values })
        toast('Nutrient updated', { type: 'success' })
      } else {
        create({ data: values })
        toast('Nutrient created', { type: 'success' })
      }
      onSuccess()
    } catch (error) {
      setErrors(error.response.data)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize>
      {({ isSubmitting }) => (
        <Form id="formId" style={{ overflow: 'visible' }}>
          <FormikTextField
            name="name"
            fullWidth
            label="Nutrient Name"
            sx={{ my: 2 }}
          />
          <FormikTextField name="tags" fullWidth label="Tags" sx={{ mb: 2 }} />
          <FormikTextField name="key" fullWidth label="Key" sx={{ mb: 2 }} />
          <FormikAutocomplete
            name="unit"
            fullWidth
            label="Unit"
            options={['ug', 'mg', 'g']}
            sx={{ mb: 2 }}
          />

          <LoadingButton
            type="submit"
            fullWidth
            size="large"
            loading={isSubmitting}>
            {id ? 'Update Nutrient' : 'Create Nutrient'}
          </LoadingButton>
        </Form>
      )}
    </Formik>
  )
}

export default NutrientForm
