import DialogFormButton from '@/components/DialogFormButton'
import { Icon } from '@mui/material'
import React from 'react'
import MDButton from '../../../components/MDButton'
import CompositionForm from '../forms/CompositionForm'

function AddCompositionButton({
  ingredientId,
  foodId,
  disabled = false,
  ...props
}) {
  return (
    <DialogFormButton
      title="Add Composition"
      renderButton={({ onClick }) => (
        <MDButton
          onClick={onClick}
          variant="gradient"
          color="primary"
          startIcon={<Icon>add</Icon>}
          disabled={disabled}
          {...props}>
          Add Composition
        </MDButton>
      )}
      renderForm={(props) => (
        <CompositionForm
          ingredientId={ingredientId}
          foodId={foodId}
          {...props}
        />
      )}
    />
  )
}

export default AddCompositionButton
