import PageHeader from '@/components/Layout/PageHeader'
import DashboardLayout from '@/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from '@/examples/Navbars/DashboardNavbar'
import { Card } from '@mui/material'
import DietPlanTable from '@/features/diet_plan/lists/DietPlanTable'
import AddDietPlanButton from '@/features/diet_plan/buttons/AddDietPlanButton'

function DietPlanList() {
  return (
    <DashboardLayout>
      <DashboardNavbar
        title="Diet Plans"
        route={[{ label: 'Diet Plans', path: 'meal_plan/' }]}
      />

      <PageHeader
        title="Diet Plans"
        description="Diet Plans allow you to schedule meals across days and eventually assign to a client"
        actions={() => <AddDietPlanButton />}
      />
      <Card>
        <DietPlanTable />
      </Card>
    </DashboardLayout>
  )
}

export default DietPlanList
