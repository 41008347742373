import axios from 'axios'
import config from '@/config'

async function getTokens(email, password) {
  return axios.post(`${config.BASE_URL}/auth/token/`, {
    email,
    password
  })
}

async function refreshToken(refreshToken) {
  return axios.post(`${config.BASE_URL}/auth/token/`, {
    refresh: refreshToken
  })
}

export { getTokens, refreshToken }
