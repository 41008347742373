import MDInput from '@/components/MDInput'
import { Autocomplete } from '@mui/material'
import { useField } from 'formik'
import React from 'react'
import useFoodGroupQuery from '../query/foodGroupQuery'

function FoodGroupInput({ name, label, ...props }) {
  const { getFoodGroups } = useFoodGroupQuery()
  const { data, isLoading } = getFoodGroups({ limit: 1000 })

  const groups = data?.data?.results
  const groupIds = data?.data?.results?.map((d) => d.id)

  const [, { value, error, touched }, { setValue }] = useField({
    name,
    label
  })

  return !isLoading && groups ? (
    <Autocomplete
      disablePortal
      id="ac-food-groups"
      options={groupIds}
      value={value}
      getOptionLabel={(value) => groups.filter((x) => x.id === value)[0]?.name}
      onChange={(e, value) => {
        setValue(value)
      }}
      // sx={{ width: 300 }}
      renderInput={(params) => (
        // <FormikTextField name="food_group" label="Movie" {...params} />
        <MDInput
          type="text"
          label={label}
          error={touched && error}
          {...params}
        />
      )}
      {...props}
    />
  ) : null
}

export default FoodGroupInput
