import ListLoader from '@/components/List/ListLoader'
import MDTypography from '@/components/MDTypography'

import DataTable from '@/examples/Tables/DataTable'

import useRecipeQuery from '@/features/recipe/query/recipe'
import React, { useMemo } from 'react'
import DeleteRecipeButton from '../buttons/DeleteRecipeButton'
import { Icon } from '@mui/material'
import TableRowButton from '@/components/Table/TableRowButton'
import { useNavigate } from 'react-router-dom'

function RecipesTable({ foodItemId }) {
  const navigate = useNavigate()
  const { getRecipes } = useRecipeQuery()
  const { data, isLoading } = getRecipes({
    order_by: 'name',
    food_item: foodItemId
  })

  const columns = useMemo(
    () => [
      {
        Header: 'name',
        accessor: 'name',
        width: '90%',
        Cell: ({
          row: {
            original: { name, tags }
          }
        }) => (
          <>
            <MDTypography variant="body2" fontWeight="bold">
              {name}
            </MDTypography>
            <MDTypography
              variant="caption"
              sx={{ display: 'flex', alignItems: 'centers' }}>
              <Icon sx={{ mr: 1 }}>local_offer</Icon>
              {tags.join(', ')}
            </MDTypography>
          </>
        )
      },
      {
        Header: 'Actions',
        sorting: false,
        width: '10%',
        Cell: ({
          row: {
            original: { id }
          }
        }) => (
          <>
            {/* <EditRecipeButton id={id} /> */}
            <TableRowButton
              tooltip="Edit"
              onClick={() => navigate(`/recipe/recipe/${id}`)}
              sx={{ mr: 1 }}>
              <Icon>edit</Icon>
            </TableRowButton>
            <DeleteRecipeButton id={id} />
          </>
        )
      }
    ],
    []
  )

  return (
    <>
      {isLoading && <ListLoader />}

      {!!data?.data?.results && (
        <DataTable
          canSearch
          table={{
            columns: columns,
            rows: data.data.results
          }}
        />
      )}
    </>
  )
}

export default RecipesTable
