import { Box, Chip, Icon, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

import React from 'react'
import DeleteIngredientButton from '../buttons/DeleteIngredientButton'
import EditIngredientButton from '../buttons/EditIngredientButton'
import MDTypography from '@/components/MDTypography'

function IngredientCard({ ingredient, ...props }) {
  return (
    <Box display="flex" {...props}>
      <Box>
        <MDTypography variant="h4" sx={{ fontWeight: 600 }}>
          {ingredient.name}
        </MDTypography>
        <Typography variant="body2">{ingredient.scientific_name}</Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: grey[500],
            display: 'flex',
            alignItems: 'center',
            mt: 2
          }}>
          <Icon sx={{ mr: 1, mb: 1 }}>local_offer</Icon>
          {ingredient?.tags?.join(', ')}
        </Typography>
        {ingredient.vegetarian ? (
          <Chip
            color="success"
            label="Vegetarian"
            size="small"
            sx={{ color: 'white' }}
          />
        ) : (
          <Chip color="warning" label="Non-Vegetarian" size="small" />
        )}
      </Box>
      <Box sx={{ ml: 'auto' }}>
        <EditIngredientButton id={ingredient.id} sx={{ mr: 1 }} />
        <DeleteIngredientButton id={ingredient.id} />
      </Box>
    </Box>
  )
}

export default IngredientCard
