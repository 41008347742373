import DialogFormButton from '@/components/DialogFormButton'
import TableRowButton from '@/components/Table/TableRowButton'
import { Icon } from '@mui/material'
import CompositionForm from '../forms/CompositionForm'

function EditCompositionTableButton({ id, ingredientId, foodId, ...props }) {
  return (
    <DialogFormButton
      title="Edit Composition"
      renderButton={({ onClick }) => (
        <TableRowButton
          onClick={onClick}
          variant="gradient"
          color="primary"
          {...props}>
          <Icon>edit</Icon>
        </TableRowButton>
      )}
      renderForm={(props) => (
        <CompositionForm
          id={id}
          ingredientId={ingredientId}
          foodId={foodId}
          {...props}
        />
      )}
    />
  )
}

export default EditCompositionTableButton
