import FormikNonFieldError from '@/components/Formik/FormikNFError'
import { Grid, Icon } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import LoadingButton from '../../../components/LoadingButton'
import MDBox from '../../../components/MDBox'
import useAppointmentQuery from '@/features/appointments/query/appointment'
import FormikDatePicker from '@/components/Formik/FormikDatePicker'
import FormikTimePicker from '@/components/Formik/FormikTimePicker'
import FormikTextField from '@/components/Formik/FormikTextField'

function AppointmentForm({ appointmentId, onSuccess }) {
  const {
    getAppointmentSlot,
    createAppointmentSlot,
    updateAppointmentSlot,
    deleteAppointmentSlot
  } = useAppointmentQuery()
  const { data: slot, isLoading } = getAppointmentSlot({
    id: appointmentId
  })
  const { mutateAsync: create } = createAppointmentSlot()
  const { mutateAsync: update } = updateAppointmentSlot()

  const initialValues = {
    date: slot?.date || '',
    time: slot?.time || '',
    duration_minutes: slot?.duration || ''
  }

  const validationSchema = Yup.object().shape({
    date: Yup.string().required('Required'),
    time: Yup.string().required('Required'),
    duration_minutes: Yup.number().required('Required')
  })

  const onSubmit = async (values, { setErrors }) => {
    try {
      let res
      if (slot) {
        res = await update({ id: slot.id, data: values })
        toast('Appointment updated', { type: 'success' })
      } else {
        res = await create({
          data: values
        })
        toast('Appointment created', { type: 'success' })
      }
      onSuccess?.(res?.data)
    } catch (error) {
      setErrors(error?.response?.data)
      toast('Error updating appointment ', { type: 'error' })
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize>
      {({ isSubmitting, values }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormikNonFieldError />
            </Grid>

            <Grid item xs={6}>
              <MDBox display="flex" alignItems="end">
                <FormikDatePicker
                  type="date"
                  name="date"
                  label="Date"
                  variant="outlined"
                />
              </MDBox>
            </Grid>
            <Grid item xs={6}>
              <MDBox display="flex" alignItems="end">
                <FormikTimePicker
                  type="time"
                  name="time"
                  label="Time"
                  variant="outlined"
                />
              </MDBox>
            </Grid>

            <Grid item xs={6}>
              <MDBox display="flex" alignItems="end">
                <FormikTextField
                  type="number"
                  name="duration_minutes"
                  label="Duration (mins)"
                  variant="outlined"
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} sx={{ alignItems: 'end' }}>
              <LoadingButton
                startIcon={<Icon>edit</Icon>}
                color="success"
                variant="gradient"
                size="small"
                type="submit"
                loading={isSubmitting}
                fullWidth>
                {slot ? 'Update' : 'Create'}
              </LoadingButton>
              {slot && (
                <LoadingButton
                  startIcon={<Icon>edit</Icon>}
                  color="danger"
                  variant="gradient"
                  size="small"
                  fullWidth>
                  Delete
                </LoadingButton>
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default AppointmentForm
