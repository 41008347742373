import { Box, Card } from '@mui/material'
import MDBox from '../MDBox'
import MDTypography from '../MDTypography'

function SectionCard({ title, actions, children, ...props }) {
  return (
    <Card sx={{ mb: 3 }}>
      <MDBox
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
        {title && (
          <MDTypography
            ariant="body1"
            sx={{ my: 2, ml: 3, mr: 'auto', fontWeight: 500 }}>
            {typeof title === 'function' ? title() : title}
          </MDTypography>
        )}
        <Box mr={2}>{actions?.()}</Box>
      </MDBox>
      {children}
    </Card>
  )
}

export default SectionCard
