import ListLoader from '@/components/List/ListLoader'
import DashboardLayout from '@/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from '@/examples/Navbars/DashboardNavbar'

import FoodDetailCard from '@/features/fooditem/detail/FoodDetailCard'

import SectionCard from '@/components/Layout/SectionCard'
import useFoodItemQuery from '@/query/foodItem'
import { Grid } from '@mui/material'
import { useParams } from 'react-router-dom'
import RecipesTable from '@/features/recipe/lists/RecipesTable'
import AddRecipeButton from '@/features/recipe/buttons/AddRecipeButton'
import Card from '@mui/material/Card'
import MDBox from '@/components/MDBox'

function FoodDetail() {
  const { foodId } = useParams()

  const { getFoodItem } = useFoodItemQuery()
  const { data, isLoading } = getFoodItem({ id: +foodId, expand: 'food_group' })

  return (
    <DashboardLayout>
      <DashboardNavbar
        title={data ? data?.data?.name : 'Create Food'}
        route={[
          { label: 'Food Items', path: 'food/' },
          { label: 'Recipes', path: `food/food/${foodId}` }
        ]}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card my={3}>
            <MDBox p={3}>{data && <FoodDetailCard food={data.data} />}</MDBox>
          </Card>
        </Grid>
        <Grid item xs={12}>
          {!isLoading && (
            <SectionCard
              title="Recipes"
              actions={() => <AddRecipeButton foodItemId={foodId} />}>
              <RecipesTable foodItemId={foodId} />
            </SectionCard>
          )}
        </Grid>
      </Grid>

      {isLoading && <ListLoader />}
    </DashboardLayout>
  )
}

export default FoodDetail
