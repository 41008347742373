import { getTokens } from '@/services/jwt'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const authUser = createAsyncThunk(
  'user/auth',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const resp = await getTokens(email, password)
      return resp.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

const userSlice = createSlice({
  name: 'user',
  initialState: {
    tokens: {},
    user: null,
    groups: null,
    isLoggedIn: false,
    isAuthenticating: false,
    isLoading: false,
    authError: null
  },
  reducers: {
    logout: (state) => {
      state.user = null
      state.groups = null
      state.isLoggedIn = false
      state.tokens = {}
    },
    updateAccessToken: (state, { payload }) => {
      state.tokens.access = payload
    },
    postSignup: (state, { payload }) => {
      state.tokens = {
        access: payload.access,
        refresh: payload.refresh
      }
      state.user = payload.user
      state.groups = payload.user.groups
      state.isAuthenticating = false
      state.isLoggedIn = true
    },
    updateUser: (state, { payload }) => {
      state.user = payload
      state.groups = payload.groups
    }
  },
  extraReducers: (builder) => {
    builder.addCase(authUser.pending, (state) => {
      state.isAuthenticating = true
      state.authError = null
    })

    builder.addCase(authUser.rejected, (state, { payload }) => {
      state.tokens = {}
      state.authError = payload
      state.isAuthenticating = false
      state.isLoggedIn = false
    })

    builder.addCase(authUser.fulfilled, (state, { payload }) => {
      state.tokens = {
        access: payload.access,
        refresh: payload.refresh
      }
      // state.user = payload.user
      // state.groups = payload.user.groups
      state.isAuthenticating = false
      state.isLoggedIn = true
    })
  }
})

const userReducer = persistReducer(
  {
    key: 'user',
    storage,
    blacklist: ['authError']
  },
  userSlice.reducer
)

export { authUser }
export const { logout, updateAccessToken, postSignup, updateUser } =
  userSlice.actions

export default userReducer
