import ListLoader from '@/components/List/ListLoader'
import MDTypography from '@/components/MDTypography'
import TableRowButton from '@/components/Table/TableRowButton'
import DataTable from '@/examples/Tables/DataTable'

import { Icon } from '@mui/material'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import useFoodItemQuery from '../../../query/foodItem'

function FoodItemsTable() {
  const { getFoodItems } = useFoodItemQuery()
  const { data, isLoading } = getFoodItems({
    order_by: 'name'
  })

  const navigate = useNavigate()

  const columns = useMemo(
    () => [
      {
        Header: 'name',
        accessor: 'name',
        width: '90%',
        Cell: ({
          row: {
            original: { name, foods_count: foodsCount }
          }
        }) => (
          <>
            <MDTypography variant="body2" fontWeight="bold">
              {name}
            </MDTypography>
            {/* <MDTypography variant="caption">{foodsCount} Foods</MDTypography> */}
          </>
        )
      },
      {
        Header: 'Actions',
        // accessor: "name",
        sorting: false,
        width: '10%',
        Cell: ({
          row: {
            original: { id }
          }
        }) => (
          <TableRowButton
            tooltip="Edit"
            onClick={() => navigate(`/recipe/food/${id}`)}
            sx={{ mr: 1 }}>
            <Icon>edit</Icon>
          </TableRowButton>
        )
      }
    ],
    []
  )

  return (
    <>
      {isLoading && <ListLoader />}

      {data && (
        <DataTable
          canSearch
          table={{
            columns: columns,
            rows: data.data.results
          }}
        />
      )}
    </>
  )
}

export default FoodItemsTable
