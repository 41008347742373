import PageHeader from '@/components/Layout/PageHeader'
import DashboardLayout from '@/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from '@/examples/Navbars/DashboardNavbar'
import { Card } from '@mui/material'
import FoodItemsTable from '@/features/fooditem/lists/FoodItemsTable'
import AddFoodItemButton from '@/features/fooditem/buttons/AddFoodItemButton'

function FoodList() {
  return (
    <DashboardLayout>
      <DashboardNavbar
        title="Food Items List"
        route={[{ label: 'Food Items', path: 'food/' }]}
      />

      <PageHeader
        title="Food Items List"
        description="Food Items are the base for recipes. They group mulltiple recipes together."
        actions={() => <AddFoodItemButton />}
      />
      <Card>
        <FoodItemsTable />
      </Card>
    </DashboardLayout>
  )
}

export default FoodList
