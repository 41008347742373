import { useMutation, useQuery } from '@tanstack/react-query'
import DietApi from '../../../services/admin_api'
import queryClient from '../../../query/client'

function useIngredientQuery() {
  function getIngredients(params) {
    return useQuery({
      queryKey: ['ingredient', 'list'],
      queryFn: () => DietApi.Ingredient.get({ ...params, limit: 1000 })
    })
  }

  function getIngredient({ id }) {
    return useQuery({
      queryKey: ['ingredient', id],
      enabled: !!id,
      queryFn: () => DietApi.Ingredient.getOne({ id })
    })
  }

  function createIngredient() {
    return useMutation({
      mutationFn: ({ data }) => DietApi.Ingredient.create({ data }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: ['ingredient'] })
    })
  }

  function updateIngredient() {
    return useMutation({
      mutationFn: ({ id, data }) => DietApi.Ingredient.update({ id, data }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: ['ingredient'] })
    })
  }

  function deleteIngredient() {
    return useMutation({
      mutationFn: ({ id }) => DietApi.Ingredient.delete({ id }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: ['ingredient'] })
    })
  }

  return {
    getIngredients,
    getIngredient,
    createIngredient,
    updateIngredient,
    deleteIngredient
  }
}

export default useIngredientQuery
