import { Box, CircularProgress } from "@mui/material"

const { default: MDButton } = require("./MDButton")

function LoadingButton({ loading, children, ...props }) {
  return (
    <MDButton variant="gradient" color="info" size="small" {...props}>
      <Box display="flex" alignItems="center">
        {loading && (
          <Box color="#fff" mr={1}>
            <CircularProgress color="inherit" size={12} />
          </Box>
        )}
        {children}
      </Box>
    </MDButton>
  )
}

export default LoadingButton
