import DialogFormButton from '@/components/DialogFormButton'
import MDButton from '@/components/MDButton'
import { Icon } from '@mui/material'
import React from 'react'
import RecipeForm from '../forms/RecipeForm'

function AddRecipeButton({ foodItemId, ...props }) {
  return (
    <DialogFormButton
      title="Add Recipe"
      renderButton={({ onClick }) => (
        <MDButton
          onClick={onClick}
          variant="gradient"
          color="info"
          size="small"
          startIcon={<Icon>add</Icon>}
          {...props}>
          Add Recipe
        </MDButton>
      )}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
      renderForm={(props) => <RecipeForm foodItemId={foodItemId} {...props} />}
    />
  )
}

export default AddRecipeButton
