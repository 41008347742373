import FormikNonFieldError from '@/components/Formik/FormikNFError'
import FormikTextField from '@/components/Formik/FormikTextField'
import NutrientInput from '@/features/nutrients/NutrientInput'
import { Grid, Icon, InputAdornment } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import LoadingButton from '../../../components/LoadingButton'
import MDBox from '../../../components/MDBox'
import MDTypography from '../../../components/MDTypography'
import useNutrientQuery from '../../../query/nutrient'
import useCompositionQuery from '@/features/composition/query/composition'

function CompositionForm({ id, foodId, ingredientId, onSuccess }) {
  const { getNutrients } = useNutrientQuery()
  const { getComposition, createComposition, updateComposition } =
    useCompositionQuery()

  const { data: composition, isLoading } = getComposition({ id })
  const { mutateAsync: createComp } = createComposition()
  const { mutateAsync: updateComp } = updateComposition()
  const comp = composition?.data || null

  const { data } = getNutrients()
  const nutrients = data?.data.results || []

  console.log('comp', comp)
  const initialValues = {
    nutrient: comp?.nutrient || '',
    quantity: comp?.quantity || '',
    recipe: comp?.recipe || foodId,
    ingredient: comp?.ingredient || ingredientId
  }

  const validationSchema = Yup.object().shape({
    nutrient: Yup.number().required('Required'),
    quantity: Yup.number().required('Required')
  })

  const onSubmit = async (values, { setErrors }) => {
    try {
      let res
      if (comp) {
        res = await updateComp({ id: comp.id, data: values })
        toast('Composition updated', { type: 'success' })
      } else {
        res = await createComp({
          data: { ...values, food_item: foodId, ingredient: ingredientId }
        })
        toast('Composition created', { type: 'success' })
      }
      onSuccess?.(res?.data)
    } catch (error) {
      setErrors(error?.response?.data)
      toast('Error updating composition ', { type: 'error' })
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize>
      {({ isSubmitting, values }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormikNonFieldError />
            </Grid>
            <Grid item xs={8}>
              <NutrientInput
                name="nutrient"
                label="Nutrient"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={4}>
              <MDBox display="flex" alignItems="end">
                <FormikTextField
                  type="number"
                  name="quantity"
                  label="Quantity"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <MDTypography variant="subtitle2">
                          {nutrients.filter((x) => x.id === values.nutrient)[0]
                            ?.unit || 'mg'}
                        </MDTypography>
                      </InputAdornment>
                    )
                  }}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} sx={{ alignItems: 'end' }}>
              <LoadingButton
                startIcon={<Icon>edit</Icon>}
                color="success"
                variant="gradient"
                size="small"
                type="submit"
                loading={isSubmitting}
                fullWidth>
                {comp ? 'Update' : 'Create'}
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default CompositionForm
