import ListLoader from '@/components/List/ListLoader'
import MDTypography from '@/components/MDTypography'
import DataTable from '@/examples/Tables/DataTable'
import React, { useMemo } from 'react'
import DeleteDietPlanButton from '../buttons/DeleteDietPlanButton'
import { Icon } from '@mui/material'
import TableRowButton from '@/components/Table/TableRowButton'
import { useNavigate } from 'react-router-dom'
import useDietPlanQuery from '@/features/diet_plan/query/diet_plan'

function DietPlanTable() {
  const navigate = useNavigate()
  const { getDietPlans } = useDietPlanQuery()
  const { data, isLoading } = getDietPlans({
    order_by: 'name'
  })

  const columns = useMemo(
    () => [
      {
        Header: 'name',
        accessor: 'name',
        width: '90%',
        Cell: ({
          row: {
            original: { name, tags }
          }
        }) => (
          <>
            <MDTypography variant="body2" fontWeight="bold">
              {name}
            </MDTypography>
            {/* <MDTypography */}
            {/*  variant="caption" */}
            {/*  sx={{ display: 'flex', alignItems: 'centers' }}> */}
            {/*  <Icon sx={{ mr: 1 }}>local_offer</Icon> */}
            {/*  {tags.join(', ')} */}
            {/* </MDTypography> */}
          </>
        )
      },
      {
        Header: 'Actions',
        sorting: false,
        width: '10%',
        Cell: ({
          row: {
            original: { id }
          }
        }) => (
          <>
            {/* <EditRecipeButton id={id} /> */}
            <TableRowButton
              tooltip="Edit"
              onClick={() => navigate(`/meal_plan/diet_plan/${id}`)}
              sx={{ mr: 1 }}>
              <Icon>edit</Icon>
            </TableRowButton>
            <DeleteDietPlanButton id={id} />
          </>
        )
      }
    ],
    []
  )

  return (
    <>
      {isLoading && <ListLoader />}

      {!!data?.data?.results && (
        <DataTable
          canSearch
          table={{
            columns: columns,
            rows: data.data.results
          }}
        />
      )}
    </>
  )
}

export default DietPlanTable
