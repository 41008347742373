import PageHeader from '@/components/Layout/PageHeader'
import MDButton from '@/components/MDButton'
import DashboardLayout from '@/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from '@/examples/Navbars/DashboardNavbar'

import { Card, Icon } from '@mui/material'

import { useNavigate } from 'react-router-dom'
import IngredientsTable from '@/features/ingredients/lists/IngredientTable'

function IngredientList() {
  const navigate = useNavigate()

  return (
    <DashboardLayout>
      <DashboardNavbar
        title="Ingredients List"
        route={[{ label: 'Ingredients', path: 'data/ingredient' }]}
      />

      <PageHeader
        title="Ingredient List"
        description="Use this page to add, update and delete food ingredients"
        actions={() => (
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              navigate('/data/ingredient/create')
            }}>
            <Icon>add</Icon> Add Ingredient
          </MDButton>
        )}
      />

      <Card>
        <IngredientsTable />
      </Card>
    </DashboardLayout>
  )
}

export default IngredientList
