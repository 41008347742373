import { Form, Formik } from 'formik'
import React from 'react'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import FormikTextField from '../../../components/Formik/FormikTextField'
import LoadingButton from '../../../components/LoadingButton'
import Grid from '@mui/material/Grid'
import FormikAutocomplete from '@/components/Formik/FormikAutocomplete'
import RecipeInput from '@/features/recipe/inputs/RecipeInput'
import useMealQuery from '@/features/diet_plan/query/meal'

function MealForm({ mealId, scheduledMealId, onSuccess }) {
  const { getMeal, updateMeal, createMeal } = useMealQuery()
  const { data, isLoading } = getMeal({ id: mealId })
  const { mutateAsync: update, isLoading: isUpdating } = updateMeal()
  const { mutateAsync: create, isLoading: isCreating } = createMeal()

  const meal = data?.data

  const initialValues = {
    recipe: meal?.recipe.id || null,
    quantity: meal?.quantity || 100,
    unit: meal?.unit || 'g',
    meal: meal?.meal || scheduledMealId || ''
  }

  const validationSchema = Yup.object().shape({
    recipe: Yup.number().required('Required'),
    quantity: Yup.number().required('Required'),
    unit: Yup.string().required('Required')
  })

  const onSubmit = async (values, { setErrors }) => {
    console.log('onSubmit')
    console.log(values)
    try {
      if (mealId) {
        await update({
          id: mealId,
          data: values
        })
        toast('Schedule updated', { type: 'success' })
      } else {
        await create({ data: values })
        toast('Schedule created', { type: 'success' })
      }
      onSuccess()
    } catch (error) {
      setErrors(error.response.data)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize>
      {({ isSubmitting }) => (
        <Form id="formId" style={{ overflow: 'visible' }}>
          <Grid container>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <RecipeInput name="recipe" label="Recipe" />
            </Grid>
            <Grid item xs={6}>
              <FormikTextField
                name="quantity"
                type="number"
                fullWidth
                label="Quantity"
              />
            </Grid>

            <Grid item xs={6}>
              <FormikAutocomplete
                name="unit"
                label="Unit"
                fullWidth
                options={['g', 'mg']}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <LoadingButton
                type="submit"
                fullWidth
                size="large"
                loading={isSubmitting}
                sx={{ mt: 2 }}>
                {mealId ? 'Update Meal' : 'Create Meal'}
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default MealForm
