import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AppointmentSlotList from '@/features/appointments/list/AppointmentSlotList'
import ClientList from '@/pages/client/ClientList'

export default function () {
  return (
    <Routes>
      <Route path="/" element={<ClientList />} />
    </Routes>
  )
}
