import DialogFormButton from '@/components/DialogFormButton'
import MDButton from '@/components/MDButton'
import { Icon } from '@mui/material'

import FoodItemForm from '../forms/FoodItemForm'

function EditFoodItemButton({ id, ...props }) {
  return (
    <DialogFormButton
      title="Edit Food item"
      renderButton={({ onClick }) => (
        <MDButton
          size="small"
          variant="gradient"
          color="info"
          onClick={onClick}
          startIcon={<Icon>edit</Icon>}
          {...props}>
          Edit
        </MDButton>
      )}
      renderForm={({ onSuccess, onCancel }) => (
        <FoodItemForm id={id} onSuccess={onSuccess} onCancel={onCancel} />
      )}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    />
  )
}

export default EditFoodItemButton
