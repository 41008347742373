import PageHeader from '@/components/Layout/PageHeader'
import DashboardLayout from '@/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from '@/examples/Navbars/DashboardNavbar'
import AddFoodGroupButton from '@/features/foodGroups/buttons/AddFoodGroupButton'
import FoodGroupsTable from '@/features/foodGroups/lists/FoodGroupsTable'
import { Card } from '@mui/material'

function FoodGroupsList() {
  return (
    <DashboardLayout>
      <DashboardNavbar
        title="Food Groups"
        route={[{ label: 'Food Groups', path: 'data/food_groups' }]}
      />

      <PageHeader
        title="Food Groups"
        description="Use this page to add, update and delete food groups"
        actions={() => <AddFoodGroupButton />}
      />

      <Card>
        <FoodGroupsTable />
      </Card>
    </DashboardLayout>
  )
}

export default FoodGroupsList
