import { useMutation, useQuery } from '@tanstack/react-query'
import DietApi from '@/services/admin_api'
import queryClient from '@/query/client'

function useMealQuery() {
  function getMeals(params) {
    return useQuery({
      queryKey: ['meal', 'list'],
      cacheTime: 1000 * 60 * 60,
      staleTime: 1000 * 60,
      queryFn: () => DietApi.Meal.get({ ...params, limit: 1000 })
    })
  }

  function getMeal({ id }) {
    return useQuery({
      queryKey: ['meal', id],
      enabled: !!id,
      queryFn: () => DietApi.Meal.getOne({ id })
    })
  }

  function createMeal() {
    return useMutation({
      mutationFn: async ({ data }) => DietApi.Meal.create({ data }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['meal'] })
        queryClient.invalidateQueries({ queryKey: ['scheduled_meal'] })
      }
    })
  }

  function updateMeal() {
    return useMutation({
      mutationFn: async ({ id, data }) => DietApi.Meal.update({ id, data }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['meal'] })
        queryClient.invalidateQueries({ queryKey: ['scheduled_meal'] })
      }
    })
  }

  function deleteMeal() {
    return useMutation({
      mutationFn: async ({ id }) => DietApi.Meal.delete({ id }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['meal'] })
        queryClient.invalidateQueries({ queryKey: ['scheduled_meal'] })
      }
    })
  }

  return {
    getMeals,
    getMeal,
    createMeal,
    updateMeal,
    deleteMeal
  }
}

export default useMealQuery
