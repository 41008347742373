import DialogFormButton from '@/components/DialogFormButton'
import MDButton from '@/components/MDButton'
import { Icon } from '@mui/material'
import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import MealForm from '@/features/diet_plan/forms/MealForm'

function AddMealButton({ scheduledMealId, ...props }) {
  return (
    <DialogFormButton
      title="Add Scheduled Meal"
      renderButton={({ onClick }) => (
        <Tooltip title="Add Meal" arrow>
          <MDButton
            onClick={onClick}
            variant="text"
            color="info"
            size="small"
            startIcon={<Icon>add</Icon>}
            {...props}>
            Add Meal
          </MDButton>
        </Tooltip>
      )}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
      renderForm={(props) => (
        <MealForm scheduledMealId={scheduledMealId} {...props} />
      )}
    />
  )
}

export default AddMealButton
