import DialogFormButton from '@/components/DialogFormButton'
import MDButton from '@/components/MDButton'
import { Icon } from '@mui/material'
import React from 'react'
import ScheduledMealForm from '../forms/ScheduledMealForm'
import Tooltip from '@mui/material/Tooltip'

function AddScheduledMealButton({ defaultDay, dietPlanId, ...props }) {
  return (
    <DialogFormButton
      title="Add Scheduled Meal"
      renderButton={({ onClick }) => (
        <Tooltip title="Add Meal" arrow>
          <MDButton
            onClick={onClick}
            variant="gradient"
            color="info"
            size="small"
            iconOnly
            {...props}>
            <Icon>add</Icon>
          </MDButton>
        </Tooltip>
      )}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
      renderForm={(props) => (
        <ScheduledMealForm
          defaultDay={defaultDay}
          dietPlanId={dietPlanId}
          {...props}
        />
      )}
    />
  )
}

export default AddScheduledMealButton
