import React from 'react'
import { Route, Routes } from 'react-router-dom'
import FoodDetail from './FoodDetail'
import FoodList from './FoodList'
import RecipeDetail from '@/pages/food/RecipeDetail'

export default function () {
  return (
    <Routes>
      {/* <Route path="/create" element={<AppointmentDetail />} /> */}
      <Route path="/food/:foodId" element={<FoodDetail />} />
      <Route path="/recipe/:recipeId" element={<RecipeDetail />} />
      <Route path="/" element={<FoodList />} />
    </Routes>
  )
}
