import api from './api'

const BASE_URL = '/api/a'

const URLS = {
  FoodData: {
    FoodGroup: `${BASE_URL}/food-data/food_group/`,
    Nutrient: `${BASE_URL}/food-data/nutrient/`,
    Ingredient: `${BASE_URL}/food-data/ingredient/`
  },
  Recipe: {
    FoodItem: `${BASE_URL}/recipe/food_item/`,
    Recipe: `${BASE_URL}/recipe/recipe/`,
    RecipeIngredient: `${BASE_URL}/recipe/recipe_ingredient/`
  },
  Composition: {
    Composition: `${BASE_URL}/composition/composition/`
  },
  MealPlan: {
    DietPlan: `${BASE_URL}/meal_plan/diet_plan/`,
    ScheduledMeal: `${BASE_URL}/meal_plan/scheduled_meal/`,
    SchelduledMealOption: `${BASE_URL}/meal_plan/scheduled_meal_option/`,
    Meal: `${BASE_URL}/meal_plan/meal/`
  },
  Appointment: {
    Appointment: `/api/p/appointment/appointment/`,
    Slot: `/api/p/appointment/slot/`
  },

  Client: {
    Client: `/api/p/client/client/`
  }
}

function generateService(path) {
  return {
    get: (params) => api.get(path, { params }),
    getOne: ({ id }) => api.get(`${path}${id}/`),
    create: ({ data }) => api.post(path, data),
    update: ({ id, data }) => api.put(`${path}${id}/`, data),
    delete: ({ id }) => api.delete(`${path}${id}/`)
  }
}

const DietApi = {
  FoodGroup: generateService(URLS.FoodData.FoodGroup),
  Nutrient: generateService(URLS.FoodData.Nutrient),
  Ingredient: {
    ...generateService(URLS.FoodData.Ingredient),

    getCompositions: ({ id }) =>
      api.get(`${URLS.FoodData.Ingredient}${id}/compositions/`)
  },

  Recipe: generateService(URLS.Recipe.Recipe),
  RecipeIngredient: generateService(URLS.Recipe.RecipeIngredient),
  FoodItem: generateService(URLS.Recipe.FoodItem),

  Composition: generateService(URLS.Composition.Composition),

  DietPlan: generateService(URLS.MealPlan.DietPlan),
  ScheduledMeal: generateService(URLS.MealPlan.ScheduledMeal),
  ScheduledMealOption: generateService(URLS.MealPlan.SchelduledMealOption),
  Meal: generateService(URLS.MealPlan.Meal),

  Appointment: generateService(URLS.Appointment.Appointment),
  AppointmentSlot: {
    ...generateService(URLS.Appointment.Slot),
    book: ({ id, data }) =>
      api.post(`${URLS.Appointment.Slot}${id}/book/`, data),
    cancel: ({ id }) => api.post(`${URLS.Appointment.Slot}${id}/cancel/`)
  },

  Client: {
    ...generateService(URLS.Client.Client)
  }
}

export default DietApi
