import userReducer from "@/pages/auth/userSlice"
import { configureStore } from "@reduxjs/toolkit"
import { combineReducers } from "redux"
import { persistStore } from "redux-persist"

import instance, { configureApi } from "@/services/api"

const rootReducer = combineReducers({
  user: userReducer,
})

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  devTools: true,
})

const persistor = persistStore(store)

configureApi(instance, store)

export { store, persistor }
