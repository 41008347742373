import FormikAutocomplete from '@/components/Formik/FormikAutocomplete'
import { MenuItem } from '@mui/material'
import useClientQuery from '@/features/client/query/client'

function ClientSelect({ name = 'client', label = 'Client' }) {
  const { getClients } = useClientQuery()
  const { data: clients } = getClients()

  function getName(client) {
    if (!client) return ''
    return `${client?.user.first_name} ${client?.user.last_name}`
  }

  return (
    <FormikAutocomplete
      fullWidth
      name={name}
      label={label}
      getOptionLabel={(option) =>
        getName(clients?.data?.results.find((x) => x.id === option))
      }
      renderOption={(props, option) => (
        <MenuItem value={option} {...props} key={option}>
          {getName(clients?.data?.results.find((x) => x.id === option))}
        </MenuItem>
      )}
      options={clients?.data?.results.map((x) => x.id) || []}
    />
  )
}

export default ClientSelect
