import { Form, Formik } from 'formik'
import React from 'react'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import FormikTextField from '../../../components/Formik/FormikTextField'
import LoadingButton from '../../../components/LoadingButton'
import useFoodGroupQuery from '../query/foodGroupQuery'

function FoodGroupForm({ foodGroupId, onSuccess }) {
  const { getFoodGroup, createFoodGroup, updateFoodGroup } = useFoodGroupQuery()
  const { data: foodGroup, isLoading } = getFoodGroup(foodGroupId)
  const { mutateAsync: updateGroup, isLoading: isUpdating } = updateFoodGroup()
  const { mutateAsync: createGroup, isLoading: isCreating } = createFoodGroup()

  const initialValues = {
    name: foodGroup?.data?.name || ''
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required')
  })

  const onSubmit = async (values, { setErrors }) => {
    try {
      if (foodGroupId) {
        await updateGroup({ id: foodGroupId, data: values })
        toast('Food group updated', { type: 'success' })
      } else {
        await createGroup({ data: values })
        toast('Food group created', { type: 'success' })
      }
      onSuccess()
    } catch (error) {
      setErrors(error.response.data)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize>
      {({ isSubmitting }) => (
        <Form id="formId" style={{ overflow: 'visible' }}>
          <FormikTextField
            name="name"
            fullWidth
            label="Food Group Name"
            sx={{ my: 1 }}
          />
          <LoadingButton
            type="submit"
            fullWidth
            size="large"
            loading={isSubmitting}
            sx={{ mt: 2 }}>
            {foodGroupId ? 'Update Food Group' : 'Create Food Group'}
          </LoadingButton>
        </Form>
      )}
    </Formik>
  )
}

export default FoodGroupForm
