import DialogFormButton from '@/components/DialogFormButton'
import TableRowButton from '@/components/Table/TableRowButton'
import { Icon } from '@mui/material'
import RecipeIngredientForm from '@/features/recipe/recipe-ingredient/forms/RecipeIngredientForm'

function EditRecipeIngredientButton({ id, recipeId, ...props }) {
  return (
    <DialogFormButton
      title="Edit Food Ingredient"
      renderButton={({ onClick }) => (
        <TableRowButton
          color="info"
          variant="outlined"
          onClick={onClick}
          tooltip="Edit"
          {...props}>
          <Icon>edit</Icon>
        </TableRowButton>
      )}
      renderForm={({ onSuccess, onCancel }) => (
        <RecipeIngredientForm
          id={id}
          recipeId={recipeId}
          onSuccess={onSuccess}
          onCancel={onCancel}
        />
      )}
    />
  )
}

export default EditRecipeIngredientButton
