import { Box, Icon, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import EditDietPlanButton from '@/features/diet_plan/buttons/EditDietPlanButton'
import DeleteDietPlanButton from '@/features/diet_plan/buttons/DeleteDietPlanButton'

function DietPlanCard({ dietPlan }) {
  return (
    <Box display="flex">
      <Box>
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          {dietPlan?.name}
        </Typography>
        <Typography variant="body2">{dietPlan?.description}</Typography>

        {dietPlan?.tags?.length > 0 && (
          <Typography
            variant="subtitle2"
            sx={{ color: grey[500], display: 'flex', alignItems: 'center' }}>
            <Icon sx={{ mr: 1 }}>local_offer</Icon>
            {dietPlan.tags.join(', ')}
          </Typography>
        )}
      </Box>
      <Box sx={{ ml: 'auto' }}>
        <EditDietPlanButton id={dietPlan.id} sx={{ mr: 1 }} />
        <DeleteDietPlanButton id={dietPlan.id} />
      </Box>
    </Box>
  )
}

export default DietPlanCard
