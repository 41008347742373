import MDBox from '@/components/MDBox'
import MDTypography from '@/components/MDTypography'
import Tooltip from '@mui/material/Tooltip'
import MDButton from '@/components/MDButton'
import Icon from '@mui/material/Icon'
import AddScheduledMealButton from '@/features/diet_plan/buttons/AddScheduledMealButton'
import Card from '@mui/material/Card'
import { round } from 'lodash'

function ColumnHeader({
  id,
  isLastColumn,
  addColumn,
  dietPlan,
  scheduledMeals
}) {
  console.log('isLastColumn', isLastColumn)

  // Get a combined list of all compositions for all meals in this column
  const compositions = []
  scheduledMeals?.forEach((meal) => {
    if (meal.day === id) {
      meal.compositions?.forEach((composition) => {
        if (
          !compositions.find((c) => c?.nutrient?.id === composition.nutrient.id)
        ) {
          compositions.push({
            nutrient: composition.nutrient,
            quantity: 0
          })
        }

        compositions.find(
          (c) => c?.nutrient?.id === composition.nutrient.id
        ).quantity += composition.quantity
      })
    }
  })

  console.log('compositions', compositions)

  return (
    <Card
      sx={{
        overflow: 'hidden',
        width: 'calc(300px)',
        p: 2,
        mb: 2.5,
        lineHeigh: 1.5,
        mx: 3
        // backgroundColor: ({ palette: { dark } }) => dark.main,
        // color: '#fff'
      }}>
      <MDBox display="flex" alignItems="center">
        <MDTypography variant="h6" color="inherit">
          Day {id}
        </MDTypography>
        <Tooltip title="Add Meal" sx={{ ml: 'auto' }}>
          <AddScheduledMealButton defaultDay={id} dietPlanId={dietPlan.id} />
        </Tooltip>
        {isLastColumn && (
          <Tooltip title="Add Day">
            <MDButton size="small" iconOnly onClick={() => addColumn()}>
              <Icon
                sx={{
                  fontWeight: 'bold',
                  color: ({ palette: { dark } }) => dark.main
                }}>
                list
              </Icon>
            </MDButton>
          </Tooltip>
        )}
      </MDBox>
      {compositions.map((composition) => (
        <MDBox
          key={composition.nutrient.id}
          sx={{
            display: 'flex',
            mt: 1
          }}>
          <MDTypography
            variant="caption"
            color="inherit"
            sx={{ fontWeight: 'bold' }}>
            {composition.nutrient.name}
          </MDTypography>
          <MDTypography variant="caption" sx={{ ml: 'auto' }}>
            {round(parseFloat(composition.quantity), 2)}
            {composition.nutrient.unit}
          </MDTypography>
        </MDBox>
      ))}
    </Card>
  )
}

export default ColumnHeader
