import ListLoader from '@/components/List/ListLoader'
import MDBox from '@/components/MDBox'
import DashboardLayout from '@/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from '@/examples/Navbars/DashboardNavbar'

import SectionCard from '@/components/Layout/SectionCard'
import { Box, Grid } from '@mui/material'
import { useParams } from 'react-router-dom'
import useRecipeQuery from '@/features/recipe/query/recipe'
import RecipeCard from '@/features/recipe/detail/RecipeCard'
import RecipeIngredientList from '@/features/recipe/recipe-ingredient/lists/RecipeIngredientList'
import AddRecipeIngredientButton from '@/features/recipe/recipe-ingredient/buttons/AddRecipeIngredientButton'
import RecipeCompositionTable from '@/features/recipe/lists/RecipeCompositionTable'
import RecipInstructionsForm from '@/features/recipe/forms/RecipeInstructionsForm'
import MDTypography from '@/components/MDTypography'
import { grey } from '@mui/material/colors'

function RecipeDetail() {
  const { recipeId } = useParams()

  const { getRecipe } = useRecipeQuery()
  const { data, isLoading } = getRecipe({ id: +recipeId, expand: 'food' })

  return (
    <DashboardLayout>
      <DashboardNavbar
        title={data ? data?.data?.name : 'Recipe'}
        route={[
          { label: 'Food Items', path: 'food/' },
          { label: 'Recipes', path: `food/food/${data?.data?.food_item}` },
          { label: 'Edit Recipe' }
        ]}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MDBox p={3}>{data && <RecipeCard recipe={data.data} />}</MDBox>
        </Grid>
        <Grid item xs={6}>
          {!isLoading && (
            <>
              <SectionCard
                title="Ingredients"
                actions={() => (
                  <AddRecipeIngredientButton recipeId={recipeId} />
                )}>
                <RecipeIngredientList recipeId={recipeId} />
              </SectionCard>
              <SectionCard title="Cooking Instructions">
                <MDBox px={3} pb={3}>
                  <RecipInstructionsForm recipe={data.data} />
                </MDBox>
              </SectionCard>
            </>
          )}
        </Grid>
        <Grid item xs={6}>
          {!isLoading && (
            <SectionCard>
              <MDTypography
                ariant="body1"
                sx={{ my: 2, ml: 3, mr: 'auto', fontWeight: 500 }}>
                Composition{' '}
                <Box sx={{ color: grey[400], display: 'inline' }}>
                  per {data?.data?.serving_size || 100}g
                </Box>
              </MDTypography>
              <RecipeCompositionTable recipeId={recipeId} />
            </SectionCard>
          )}
        </Grid>
      </Grid>

      {isLoading && <ListLoader />}
    </DashboardLayout>
  )
}

export default RecipeDetail
