import { Form, Formik } from 'formik'
import React from 'react'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import FormikTextField from '../../../components/Formik/FormikTextField'
import LoadingButton from '../../../components/LoadingButton'
import FormikTagsField from '@/components/Formik/FormikTagsField'
import useDietPlanQuery from '@/features/diet_plan/query/diet_plan'

function DietPlanForm({ dietPlanId, onSuccess }) {
  const { getDietPlan, createDietPlan, updateDietPlan } = useDietPlanQuery()
  const { data, isLoading } = getDietPlan({ id: dietPlanId })
  const { mutateAsync: update, isLoading: isUpdating } = updateDietPlan()
  const { mutateAsync: create, isLoading: isCreating } = createDietPlan()

  const dietPlan = data?.data

  const initialValues = {
    name: dietPlan?.name || '',
    description: dietPlan?.description || [],
    tags: dietPlan?.tags || []
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required')
  })

  const onSubmit = async (values, { setErrors }) => {
    try {
      if (dietPlanId) {
        await update({ id: dietPlanId, data: values })
        toast('Diet Plan updated', { type: 'success' })
      } else {
        await create({ data: values })
        toast('Diet Plan created', { type: 'success' })
      }
      onSuccess()
    } catch (error) {
      setErrors(error.response.data)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize>
      {({ isSubmitting }) => (
        <Form id="formId" style={{ overflow: 'visible' }}>
          <FormikTextField name="name" fullWidth label="Name" sx={{ my: 1 }} />
          <FormikTextField
            name="description"
            multiline
            rows={4}
            fullWidth
            label="Description"
            sx={{ my: 1 }}
          />
          <FormikTagsField name="tags" fullWidth label="Tags" sx={{ my: 1 }} />
          <LoadingButton
            type="submit"
            fullWidth
            size="large"
            loading={isSubmitting}
            sx={{ mt: 2 }}>
            {dietPlanId ? 'Update Diet Plan' : 'Create Diet Plan'}
          </LoadingButton>
        </Form>
      )}
    </Formik>
  )
}

export default DietPlanForm
