import DialogFormButton from '@/components/DialogFormButton'
import MDButton from '@/components/MDButton'
import { Icon } from '@mui/material'

import IngredientForm from '../forms/IngredientForm'

function EditIngredientButton({ id, ...props }) {
  return (
    <DialogFormButton
      title="Edit Ingredient"
      renderButton={({ onClick }) => (
        <MDButton
          size="small"
          variant="gradient"
          color="info"
          onClick={onClick}
          startIcon={<Icon>edit</Icon>}
          {...props}>
          Edit
        </MDButton>
      )}
      renderForm={({ onSuccess, onCancel }) => (
        <IngredientForm id={id} onSuccess={onSuccess} onCancel={onCancel} />
      )}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    />
  )
}

export default EditIngredientButton
