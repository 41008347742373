import useFoodItemQuery from '@/query/foodItem'
import { Form, Formik } from 'formik'
import React from 'react'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import FormikTextField from '../../../components/Formik/FormikTextField'
import LoadingButton from '../../../components/LoadingButton'
import FoodGroupInput from '../../foodGroups/inputs/FoodGroupInput'
import FormikTagsField from '@/components/Formik/FormikTagsField'

function FoodItemForm({ id, onSuccess }) {
  const { getFoodItem, createFoodItem, updateFoodItem } = useFoodItemQuery()
  const { data } = getFoodItem({ id })
  const { mutate: update } = updateFoodItem()
  const { mutate: create } = createFoodItem()

  const initialValues = {
    name: data?.data?.name || '',
    tags: data?.data?.tags || []
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required')
  })

  const onSubmit = async (values, { setErrors }) => {
    try {
      if (id) {
        update({ id: id, data: values })
        toast('Food item updated', { type: 'success' })
      } else {
        create({ data: values })
        toast('Food item created', { type: 'success' })
      }
      onSuccess()
    } catch (error) {
      setErrors(error.response.data)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize>
      {({ isSubmitting }) => (
        <Form id="formId" style={{ overflow: 'visible' }}>
          <FormikTextField
            name="name"
            fullWidth
            label="Food Name"
            sx={{ mb: 2 }}
          />
          <FormikTagsField name="tags" label="Tags" fullWidth />
          <FoodGroupInput name="food_group" label="Food Group" sx={{ my: 2 }} />
          <LoadingButton
            type="submit"
            fullWidth
            size="large"
            loading={isSubmitting}>
            {id ? 'Update' : 'Create'}
          </LoadingButton>
        </Form>
      )}
    </Formik>
  )
}

export default FoodItemForm
