import { Dialog, DialogContent, DialogTitle } from "@mui/material"
import NutrientForm from "./NutrientForm"

function NutrientDialog({ open, onClose, id }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>{id ? "Update" : "Create Food Group"}</DialogTitle>
      <DialogContent>
        <NutrientForm id={id} onSuccess={onClose} />
      </DialogContent>
    </Dialog>
  )
}

export default NutrientDialog
