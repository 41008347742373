import { useMutation, useQuery } from "@tanstack/react-query"
import DietApi from '../services/admin_api'
import queryClient from "./client"

function useNutrientQuery() {
  function getNutrients(params) {
    return useQuery({
      queryKey: ["nutrient", "list"],
      cacheTime: 1000 * 60 * 60,
      staleTime: 1000 * 60,
      queryFn: () => DietApi.Nutrient.get({ ...params, limit: 1000 }),
    })
  }

  function getNutrient({ id }) {
    return useQuery({
      queryKey: ["nutrient", id],
      queryFn: () => DietApi.Nutrient.getOne({ id }),
    })
  }

  function createNutrient() {
    return useMutation({
      mutationFn: async ({ data }) => DietApi.Nutrient.create({ data }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: ["nutrient"] }),
    })
  }

  function updateNutrient() {
    return useMutation({
      mutationFn: async ({ id, data }) => DietApi.Nutrient.update({ id, data }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: ["nutrient"] }),
    })
  }

  function deleteNutrient() {
    return useMutation({
      mutationFn: async ({ id }) => DietApi.Nutrient.delete({ id }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: ["nutrient"] }),
    })
  }

  return {
    getNutrients,
    getNutrient,
    createNutrient,
    updateNutrient,
    deleteNutrient,
  }
}

export default useNutrientQuery
