import {
  ButtonBase,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon
} from '@mui/material'

import { useState } from "react"

function DialogFormButton({
  title,
  renderForm,
  renderButton,
  dialogProps,
  formProps,
}) {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {renderButton({ onClick: handleOpen })}
      <Dialog {...dialogProps} open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <ButtonBase
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            p: 1,
            borderRadius: '50%',
            fontSize: 22
          }}>
          <Icon fontSize="inherit" color="disabled">
            close
          </Icon>
        </ButtonBase>
        <DialogContent>
          {renderForm({
            ...formProps,
            onSuccess: handleClose,
            onCancel: handleClose
          })}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default DialogFormButton
