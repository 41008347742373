import { useMutation, useQuery } from '@tanstack/react-query'
import DietApi from '../../../services/admin_api'
import queryClient from '../../../query/client'

function useClientQuery() {
  function getClients(params) {
    return useQuery({
      queryKey: ['client', 'list'],
      queryFn: () => DietApi.Client.get({ ...params, limit: 1000 })
    })
  }

  function getClient({ id }) {
    return useQuery({
      queryKey: ['client', id],
      enabled: !!id,
      queryFn: () => DietApi.Client.getOne({ id })
    })
  }

  function createClient() {
    return useMutation({
      mutationFn: ({ data }) => DietApi.Client.create({ data }),
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['client'] })
    })
  }

  function updateClient() {
    return useMutation({
      mutationFn: ({ id, data }) => DietApi.Client.update({ id, data }),
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['client'] })
    })
  }

  function deleteClient() {
    return useMutation({
      mutationFn: ({ id }) => DietApi.Client.delete({ id }),
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['client'] })
    })
  }

  return {
    getClients,
    getClient,
    createClient,
    updateClient,
    deleteClient
  }
}

export default useClientQuery
