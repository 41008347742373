import PageHeader from '@/components/Layout/PageHeader'
import DashboardLayout from '@/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from '@/examples/Navbars/DashboardNavbar'
import { Card } from '@mui/material'
// import AddClientButton from '@/features/appointments/buttons/AddClientButton'
import ClientsTable from '@/features/client/lists/ClientTable'

function ClientList() {
  console.log('ClientList')
  return (
    <DashboardLayout>
      <DashboardNavbar
        title="Clients"
        route={[{ label: 'Clients', path: 'clients' }]}
      />
      <PageHeader
        title="Clients List"
        description="Use this page to add, update and delete clients"
        // actions={() => <AddClientButton />}
      />
      <Card>
        <ClientsTable />
      </Card>
    </DashboardLayout>
  )
}

export default ClientList
