import { useMutation, useQuery } from "@tanstack/react-query"
import DietApi from '../services/admin_api'
import queryClient from "./client"

function useFoodItemQuery() {
  function getFoodItems(params) {
    return useQuery({
      queryKey: ["foodItem", "list"],
      cacheTime: 1000 * 60 * 60,
      staleTime: 1000 * 60,
      queryFn: () => DietApi.FoodItem.get({ ...params, limit: 1000 }),
    })
  }

  function getFoodItem({ id }) {
    return useQuery({
      queryKey: ['foodItem', id],
      enabled: !!id,
      queryFn: () => DietApi.FoodItem.getOne({ id })
    })
  }

  function createFoodItem() {
    return useMutation({
      mutationFn: async ({ data }) => DietApi.FoodItem.create({ data }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: ["foodItem"] }),
    })
  }

  function updateFoodItem() {
    return useMutation({
      mutationFn: async ({ id, data }) => DietApi.FoodItem.update({ id, data }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: ["foodItem"] }),
    })
  }

  function deleteFoodItem() {
    return useMutation({
      mutationFn: async ({ id }) => DietApi.FoodItem.delete({ id }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: ["foodItem"] }),
    })
  }

  return {
    getFoodItems,
    getFoodItem,
    createFoodItem,
    updateFoodItem,
    deleteFoodItem,
  }
}

export default useFoodItemQuery
