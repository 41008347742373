import ListLoader from '@/components/List/ListLoader'
import DashboardLayout from '@/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from '@/examples/Navbars/DashboardNavbar'
import { Grid } from '@mui/material'
import { useParams } from 'react-router-dom'
import useDietPlanQuery from '@/features/diet_plan/query/diet_plan'
import DietPlanCard from '@/features/diet_plan/detail/DietPlanCard'
import MDBox from '@/components/MDBox'
import DietPlanner from '@/features/diet_plan/components/DietPlanner'

function DietPlanDetail() {
  const { dietPlanId } = useParams()

  const { getDietPlan } = useDietPlanQuery()
  console.log('dietPlanId', dietPlanId)
  const { data, isLoading } = getDietPlan({ id: +dietPlanId })

  const dietPlan = data?.data

  return (
    <DashboardLayout>
      <DashboardNavbar
        title={dietPlan?.name || 'Recipe'}
        route={[
          { label: 'Diet Plans', path: 'meal_plan/' },
          { label: 'Edit Diet Plan' }
        ]}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MDBox px={3}>{data && <DietPlanCard dietPlan={data.data} />}</MDBox>
        </Grid>
        <Grid item xs={12}>
          <MDBox>{data && <DietPlanner dietPlan={data?.data} />} </MDBox>
        </Grid>
      </Grid>

      {isLoading && <ListLoader />}
    </DashboardLayout>
  )
}

export default DietPlanDetail
