import { useField, useFormikContext } from 'formik'
import React from 'react'
import MDInput from '../MDInput'
import { Box, Chip, Typography } from '@mui/material'
import MDButton from '@/components/MDButton'

function FormikTagsField({ name, label, type = 'text', ...props }) {
  const [, { value, error, touched }, { setValue }] = useField({
    name,
    label
  })
  const { handleBlur } = useFormikContext()
  const [addMode, setAddMode] = React.useState(false)
  const [newTag, setNewTag] = React.useState('')

  // format error
  let formattedError = error
  if (Array.isArray(error)) {
    formattedError = (
      <>
        {error.map((l, i) => (
          <div key={i}>
            {l} <br />
          </div>
        ))}
      </>
    )
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <MDInput
        name={name}
        label={label}
        type={type}
        value={newTag}
        onChange={(e) => {
          setNewTag(e.target.value)
        }}
        onBlur={handleBlur}
        helperText={error && touched && formattedError}
        error={!!error}
        inputProps={{ sx: { pt: 6 } }}
        InputLabelProps={{ shrink: true }}
        {...props}
      />
      <MDButton
        size="small"
        variant="text"
        color="secondary"
        sx={{ position: 'absolute', bottom: 8, right: 0 }}
        onClick={() => {
          const newTags = [...value]
          newTags.push(newTag)
          setValue(newTags)
          setNewTag('')
          setAddMode(false)
        }}>
        Add Tag
      </MDButton>
      <Box
        sx={{
          position: 'absolute',
          top: 20,
          left: 12,
          right: 12,
          overflow: 'scroll',
          display: 'flex'
        }}>
        {value?.map((tag, i) => (
          <Chip
            size="small"
            label={tag}
            sx={{ mr: 1 }}
            onDelete={() => {
              const newTags = [...value]
              newTags.splice(i, 1)
              setValue(newTags)
            }}
          />
        ))}
        {value && value.length == 0 && (
          <Typography variant="caption">No tags added yet</Typography>
        )}
      </Box>
    </Box>
  )
}
export default FormikTagsField
