import ListLoader from '@/components/List/ListLoader'
import TableRowButton from '@/components/Table/TableRowButton'
import DataTable from '@/examples/Tables/DataTable'
import { Icon } from '@mui/material'
import { useMemo } from 'react'
import useNutrientQuery from '../../query/nutrient'
import EditNutrientButton from './buttons/EditNutrientButton'

function NutrientTable() {
  const { getNutrients, deleteNutrient } = useNutrientQuery()
  const { data, isLoading } = getNutrients()
  const { mutate: deleteNut } = deleteNutrient()

  const columns = useMemo(
    () => [
      {
        Header: 'name',
        accessor: 'name',
        width: '60%'
      },
      {
        Header: 'unit',
        accessor: 'unit',
        width: '30%'
      },
      {
        Header: 'Actions',
        // accessor: "name",
        width: '10%',
        Cell: ({
          row: {
            original: { id }
          }
        }) => (
          <>
            <EditNutrientButton id={id} />
            <TableRowButton tooltip="Delete" onClick={() => deleteNut({ id })}>
              <Icon>delete</Icon>
            </TableRowButton>
          </>
        )
      }
    ],
    []
  )

  return (
    <>
      {isLoading && <ListLoader />}
      {data && (
        <DataTable
          canSearch
          table={{
            columns: columns,
            rows: data.data.results
          }}
        />
      )}
    </>
  )
}

export default NutrientTable
