import { useMutation, useQuery } from '@tanstack/react-query'
import queryClient from '@/query/client'
import DietAdminApi from '@/services/admin_api'

function useAppointmentQuery() {
  function getAppointments(params, enabled = true) {
    return useQuery({
      queryKey: ['appointment', 'list', params],
      enabled: !!enabled,
      queryFn: () =>
        DietAdminApi.Appointment.get({
          ...params,
          limit: 1000
        })
    })
  }

  function getAppointment({ id }) {
    return useQuery({
      queryKey: ['appointment', id],
      enabled: !!id,
      queryFn: () => DietAdminApi.Appointment.getOne({ id })
    })
  }

  function createAppointment() {
    return useMutation({
      mutationFn: ({ data }) => DietAdminApi.Appointment.create({ data }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: ['appointment'] })
    })
  }

  function updateAppointment() {
    return useMutation({
      mutationFn: ({ id, data }) =>
        DietAdminApi.Appointment.update({ id, data }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: ['appointment'] })
    })
  }

  function deleteAppointment() {
    return useMutation({
      mutationFn: ({ id }) => DietAdminApi.Appointment.delete({ id }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: ['appointment'] })
    })
  }

  function getAppointmentSlots(params) {
    return useQuery({
      queryKey: ['appointment_slot'],
      queryFn: () =>
        DietAdminApi.AppointmentSlot.get({ ...params, limit: 1000 })
    })
  }

  function getAppointmentSlot({ id }) {
    return useQuery({
      queryKey: ['appointment_slot', id],
      enabled: !!id,
      queryFn: () => DietAdminApi.AppointmentSlot.getOne({ id })
    })
  }

  function createAppointmentSlot() {
    return useMutation({
      mutationFn: ({ data }) => DietAdminApi.AppointmentSlot.create({ data }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: ['appointment_slot'] })
    })
  }

  function updateAppointmentSlot() {
    return useMutation({
      mutationFn: ({ id, data }) =>
        DietAdminApi.AppointmentSlot.update({ id, data }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: ['appointment_slot'] })
    })
  }

  function deleteAppointmentSlot() {
    return useMutation({
      mutationFn: ({ id }) => DietAdminApi.AppointmentSlot.delete({ id }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: ['appointment_slot'] })
    })
  }

  function bookAppointmentSlot() {
    return useMutation({
      mutationFn: ({ id, data }) =>
        DietAdminApi.AppointmentSlot.book({ id, data }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: ['appointment_slot'] })
    })
  }

  function cancelAppointmentSlot() {
    return useMutation({
      mutationFn: ({ id }) => DietAdminApi.AppointmentSlot.cancel({ id }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: ['appointment_slot'] })
    })
  }

  return {
    getAppointments,
    getAppointment,
    createAppointment,
    updateAppointment,
    deleteAppointment,
    bookAppointmentSlot,
    cancelAppointmentSlot,

    getAppointmentSlots,
    getAppointmentSlot,
    createAppointmentSlot,
    updateAppointmentSlot,
    deleteAppointmentSlot
  }
}

export default useAppointmentQuery
