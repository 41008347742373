import React from 'react'
import { Route, Routes } from 'react-router-dom'
import DietPlanList from '@/pages/meal_plan/DietPlanList'
import DietPlanDetail from '@/pages/meal_plan/DietPlanDetail'

export default function () {
  return (
    <Routes>
      {/* <Route path="/create" element={<AppointmentDetail />} /> */}
      <Route path="/diet_plan/:dietPlanId" element={<DietPlanDetail />} />
      <Route path="/" element={<DietPlanList />} />
    </Routes>
  )
}
