import EmptyListMessage from './EmptyMessage'
import ListLoader from './ListLoader'

// ** GenericList **
// This component is a generic list component that can be used to render
// any list of items. It takes a list of items, a function to render each
// item, and a loading state. It also takes an optional empty message to
// display when the list is empty and a loaderProps object to pass to the
// ListLoader component.
function GenericList({
  items,
  renderItem,
  loading,
  emptyMessage,
  keyAccessor = (item) => item.id,
  loaderProps = {},
  msgProps = {},
  ...props
}) {
  if (loading) {
    return <ListLoader {...loaderProps} />
  }

  if (!loading && !items.length) {
    return <EmptyListMessage message={emptyMessage} {...msgProps} />
  }

  return (
    <ul {...props} style={{ listStyle: 'none' }}>
      {items.map((item, index) => (
        <li key={keyAccessor(item, index)}>{renderItem(item, index)}</li>
      ))}
    </ul>
  )
}

export default GenericList
