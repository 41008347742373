import MDInput from '@/components/MDInput'
import { Autocomplete } from '@mui/material'
import { useField } from 'formik'
import React from 'react'
import useNutrientQuery from '../../query/nutrient'

function NutrientInput({ name, label, ...props }) {
  const { getNutrients } = useNutrientQuery()
  const { data, isLoading } = getNutrients()
  const nutrients = data?.data?.results

  const [, { value, error, touched }, { setValue }] = useField({
    name,
    label
  })

  // useEffect(() => {
  //   if (!value) setValue(nutrients[0]?.id)
  // }, [value])

  return nutrients && !isLoading ? (
    <Autocomplete
      disablePortal
      disabled={isLoading}
      id="ac-food-groups"
      options={nutrients.map((d) => d.id)}
      value={value || null}
      getOptionLabel={(value) =>
        nutrients.filter((x) => x.id === value)[0]?.name || ''
      }
      onChange={(e, value) => {
        setValue(value)
      }}
      // sx={{ width: 300 }}
      renderInput={(params) => (
        // <FormikTextField name="food_group" label="Movie" {...params} />
        <MDInput
          type="text"
          label={label}
          error={touched && error}
          variant="outlined"
          {...params}
        />
      )}
    />
  ) : null
}

export default NutrientInput
