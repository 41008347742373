import { useMutation, useQuery } from '@tanstack/react-query'
import DietApi from '@/services/admin_api'
import queryClient from '@/query/client'

function useRecipeQuery() {
  function getRecipes(params) {
    return useQuery({
      queryKey: ['recipe', 'list'],
      cacheTime: 1000 * 60 * 60,
      staleTime: 1000 * 60,
      queryFn: () => DietApi.Recipe.get({ ...params, limit: 1000 })
    })
  }

  function getRecipe({ id }) {
    return useQuery({
      queryKey: ['recipe', id],
      enabled: !!id,
      queryFn: () => DietApi.Recipe.getOne({ id })
    })
  }

  function createRecipe() {
    return useMutation({
      mutationFn: async ({ data }) => DietApi.Recipe.create({ data }),
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['recipe'] })
    })
  }

  function updateRecipe() {
    return useMutation({
      mutationFn: async ({ id, data }) => DietApi.Recipe.update({ id, data }),
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['recipe'] })
    })
  }

  function deleteRecipe() {
    return useMutation({
      mutationFn: async ({ id }) => DietApi.Recipe.delete({ id }),
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['recipe'] })
    })
  }

  return {
    getRecipes,
    getRecipe,
    createRecipe,
    updateRecipe,
    deleteRecipe
  }
}

export default useRecipeQuery
