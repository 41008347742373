import MDBox from '../MDBox'
import MDTypography from '../MDTypography'

function EmptyListMessage({ message, children }) {
  return (
    <MDBox sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
      {message && <MDTypography variant="caption">{message}</MDTypography>}
      {children}
    </MDBox>
  )
}

export default EmptyListMessage
