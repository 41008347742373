/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React components

// @mui icons

// Images
import Login from "./pages/auth/login"

const routes = [
  {
    route: "/login",
    component: <Login />,
    key: "auth-login",
    type: 'collapse',
    noCollapse: true
  },
]

export default routes
