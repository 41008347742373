import DialogFormButton from '@/components/DialogFormButton'
import MDButton from '@/components/MDButton/index'
import { Icon } from '@mui/material'
import RecipeIngredientForm from '@/features/recipe/recipe-ingredient/forms/RecipeIngredientForm'

function AddRecipeIngredientButton({ recipeId, ...props }) {
  return (
    <DialogFormButton
      title="Add Ingredient"
      renderButton={({ onClick }) => (
        <MDButton
          size="small"
          variant="contained"
          color="info"
          onClick={onClick}
          startIcon={<Icon>add</Icon>}
          sx={{ ml: 'auto' }}
          {...props}>
          Add
        </MDButton>
      )}
      renderForm={({ onSuccess, onCancel }) => (
        <RecipeIngredientForm
          recipeId={recipeId}
          onSuccess={onSuccess}
          onCancel={onCancel}
        />
      )}
    />
  )
}

export default AddRecipeIngredientButton
