import { Form, Formik } from 'formik'
import React from 'react'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import FormikTextField from '../../../components/Formik/FormikTextField'
import LoadingButton from '../../../components/LoadingButton'
import useRecipeQuery from '../query/recipe'

function RecipInstructionsForm({ recipe }) {
  const { updateRecipe } = useRecipeQuery()
  const { mutateAsync: update, isLoading: isUpdating } = updateRecipe()

  const initialValues = {
    cooking_instructions: recipe?.cooking_instructions || ''
  }

  const validationSchema = Yup.object().shape({
    cooking_instructions: Yup.string().required('Required')
  })

  const onSubmit = async (values, { setErrors }) => {
    try {
      await update({ id: recipe.id, data: { ...recipe, ...values } })
      toast('Instructions updated', { type: 'success' })
    } catch (error) {
      setErrors(error.response.data)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize>
      {({ isSubmitting }) => (
        <Form id="formId" style={{ overflow: 'visible' }}>
          <FormikTextField
            name="cooking_instructions"
            fullWidth
            label="Recipe Instructions"
            multiline
            rows={8}
            sx={{ my: 1 }}
          />
          <LoadingButton
            type="submit"
            fullWidth
            size="large"
            loading={isSubmitting}
            sx={{ mt: 2 }}>
            Update Instructions
          </LoadingButton>
        </Form>
      )}
    </Formik>
  )
}

export default RecipInstructionsForm
