import { Autocomplete, FormControl, FormHelperText } from '@mui/material'
import { useField } from 'formik'
import React from 'react'
import MDInput from '../MDInput'

function FormikAutocomplete({
  name,
  label,
  options,
  children,
  fullWidth,
  shrink,
  ...props
}) {
  const [, { value, error, touched }, { setValue }] = useField({
    name,
    label
  })

  return (
    <FormControl
      size="small"
      variant="outlined"
      error={error && touched}
      fullWidth={fullWidth}>
      <Autocomplete
        label={label}
        value={value}
        options={options}
        renderInput={(params) => <MDInput {...params} label={label} />}
        onChange={(e, value) => {
          setValue(value)
        }}
        {...props}
      />
      {error && touched && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}
export default FormikAutocomplete
