import { useField } from 'formik'
import React from 'react'
import FormikAutocomplete from '@/components/Formik/FormikAutocomplete'
import useRecipeQuery from '@/features/recipe/query/recipe'

function RecipeInput({ name, label, ...props }) {
  const { getRecipes } = useRecipeQuery()
  const { data, isLoading } = getRecipes({ limit: 1000 })

  const groups = data?.data?.results
  const groupIds = data?.data?.results?.map((d) => d.id)

  const [, { value, error, touched }, { setValue }] = useField({
    name,
    label
  })

  return !isLoading && groups ? (
    <FormikAutocomplete
      fullWidth
      disablePortal
      id="ac-recipe"
      name={name}
      label={label}
      options={groupIds}
      value={value}
      getOptionLabel={(value) => groups.find((x) => x.id === value)?.name}
      {...props}
    />
  ) : null
}

export default RecipeInput
