import DialogFormButton from '@/components/DialogFormButton'
import TableRowButton from '@/components/Table/TableRowButton'
import { Icon } from '@mui/material'
import DietPlanForm from '../forms/DietPlanForm'

function EditDietPlanButton({ id, ...parentProps }) {
  return (
    <DialogFormButton
      title="Edit Diet Plan"
      renderButton={(props) => (
        <TableRowButton
          tooltip="Edit"
          sx={{ marginRight: 2 }}
          {...props}
          {...parentProps}>
          <Icon>edit</Icon>
        </TableRowButton>
      )}
      renderForm={(props) => <DietPlanForm dietPlanId={id} {...props} />}
      dialogProps={{ maxWidth: 'xs', fullWidth: true, width: 'xs' }}
    />
  )
}

export default EditDietPlanButton
