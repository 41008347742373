import DialogFormButton from '@/components/DialogFormButton'
import { Icon } from '@mui/material'
import React from 'react'
import MDButton from '../../../components/MDButton'
import AppointmentBookForm from '@/features/appointments/forms/AppointmentBookForm'

function AppointmentBookButton({ slotId, disabled = false, ...props }) {
  return (
    <DialogFormButton
      title="Book Appointment"
      renderButton={({ onClick }) => (
        <MDButton
          size="small"
          onClick={onClick}
          variant="gradient"
          color="info"
          // startIcon={<Icon>add</Icon>}
          disabled={disabled}
          {...props}>
          Book
        </MDButton>
      )}
      renderForm={(props) => <AppointmentBookForm slotId={slotId} {...props} />}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
    />
  )
}

export default AppointmentBookButton
