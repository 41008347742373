import React from "react"

import { Route, Routes } from "react-router-dom"

import FoodGroupsList from "./groupList"

export default function () {
  return (
    <Routes>
      {/* <Route path="/create" element={<FoodGroupForm />} />
      <Route path="/edit/:foodGroupId" element={<FoodGroupForm />} /> */}
      <Route path="/" element={<FoodGroupsList />} />
    </Routes>
  )
}
