import IngredientSelect from '@/features/ingredients/inputs/IngredientSelect'

import { Form, Formik } from 'formik'
import React from 'react'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import useRecipeIngredientQuery from '@/features/recipe/query/recipe-ingredients'
import FormikTextField from '@/components/Formik/FormikTextField'
import Grid from '@mui/material/Grid'
import LoadingButton from '@/components/LoadingButton'
import MenuItem from '@mui/material/MenuItem'
import FormikAutocomplete from '@/components/Formik/FormikAutocomplete'

function RecipeIngredientForm({ id, recipeId, onSuccess }) {
  const {
    getRecipeIngredient,
    createRecipeIngredient,
    updateRecipeIngredient
  } = useRecipeIngredientQuery()
  const { data: recipe, isLoading } = getRecipeIngredient({ id })
  const { mutateAsync: update, isLoading: isUpdating } =
    updateRecipeIngredient()
  const { mutateAsync: create, isLoading: isCreating } =
    createRecipeIngredient()

  const initialValues = {
    ingredient: recipe?.data?.ingredient || '',
    quantity: recipe?.data?.quantity || '',
    unit: recipe?.data?.unit || '',
    tags: recipe?.data?.tags || ''
  }

  const validationSchema = Yup.object().shape({
    // ingredient: Yup.number().required('Required'),
    // quantity: Yup.number().required('Required'),
    // unit: Yup.string().required('Required')
  })

  const onSubmit = async (values, { setErrors }) => {
    console.log('values', values)
    try {
      values = {
        ...values,
        recipe: recipeId
      }
      if (id) await update({ id, data: values })
      else await create({ data: values })

      toast(`Ingredient ${id ? 'updated' : 'created'}`, {
        type: 'success'
      })
      onSuccess()
    } catch (error) {
      setErrors(error.response.data)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize>
      {({ isSubmitting }) => (
        <Form id="formId" style={{ overflow: 'visible' }}>
          <Grid container>
            <Grid item xs={12}>
              <IngredientSelect
                name="ingredient"
                fullWidth
                // label="Recipe Name"
                sx={{ my: 1 }}
              />
            </Grid>
            <Grid item xs={8}>
              <FormikTextField
                name="quantity"
                fullWidth
                label="Quantity"
                sx={{ my: 1 }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormikAutocomplete
                name="unit"
                fullWidth
                label="Unit"
                sx={{ my: 1 }}
                renderOption={(props, option) => (
                  <MenuItem value={option} {...props} key={option}>
                    {option}
                  </MenuItem>
                )}
                options={['mg', 'g', 'kg', 'ml']}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                type="submit"
                fullWidth
                size="large"
                loading={isSubmitting}
                sx={{ mt: 2 }}>
                {id ? 'Update Ingredient' : 'Create Ingredient'}
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default RecipeIngredientForm
