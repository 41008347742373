import EmptyListMessage from '@/components/List/EmptyMessage'
import { CircularProgress } from '@mui/material'
import { useMemo } from 'react'
import MDTypography from '../../../components/MDTypography'

import DataTable from '../../../examples/Tables/DataTable'
import DeleteCompositionButton from '../../composition/buttons/DeleteCompositionButton'
import EditCompositionTableButton from '../../composition/buttons/EditCompositionTableButton'
import math from 'lodash'
import useCompositionQuery from '@/features/composition/query/composition'

function IngredientCompositionTable({ ingredientId, foodId, disabled }) {
  const { getCompositions } = useCompositionQuery()

  const { data, isLoading } = getCompositions({
    ingredient: ingredientId,
    expand: 'nutrient'
  })

  const columns = useMemo(
    () => [
      {
        Header: 'name',
        width: '70%',
        accessor: 'nutrient.name',
        search: true,
        Cell: ({
          row: {
            original: { nutrient }
          }
        }) => (
          <MDTypography
            variant="body2"
            style={{
              fontWeight: nutrient.nutrient_type === 'macro' ? 700 : 400
            }}>
            {nutrient.name}
          </MDTypography>
        )
      },
      {
        Header: 'Measure',
        width: '20%',
        search: false,
        Cell: ({
          row: {
            original: { quantity, nutrient }
          }
        }) => (
          <MDTypography
            variant="body2"
            style={{
              fontWeight: nutrient.nutrient_type === 'macro' ? 700 : 400
            }}>
            {math.round(quantity, 2)}
            {nutrient.unit}
          </MDTypography>
        )
      },
      {
        Header: 'Actions',
        // accessor: "name",
        width: '10%',
        Cell: ({
          row: {
            original: { id, ingredient }
          }
        }) => (
          <>
            <EditCompositionTableButton
              id={id}
              ingredientId={ingredient}
              sx={{ mr: 1 }}
            />
            <DeleteCompositionButton id={id} />
          </>
        )
      }
    ],
    []
  )

  return (
    <>
      {isLoading && !disabled && <CircularProgress />}
      {disabled && (
        <EmptyListMessage message="You can add nutrients once the food is ceated" />
      )}

      {data && (
        <DataTable
          canSearch
          table={{
            columns: columns,
            rows: data?.data?.results
          }}
        />
      )}
    </>
  )
}

export default IngredientCompositionTable
