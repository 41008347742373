import ListLoader from '@/components/List/ListLoader'
import MDTypography from '@/components/MDTypography'

import DataTable from '@/examples/Tables/DataTable'

import useFoodGroupQuery from '@/features/foodGroups/query/foodGroupQuery'
import { useMemo } from 'react'
import DeleteFoodGroupButton from '../buttons/DeleteFoodGroupButton'
import EditFoodGroupButton from '../buttons/EditFoodGroupButton'

function FoodGroupsTable() {
  const { getFoodGroups } = useFoodGroupQuery()
  const { data, isLoading } = getFoodGroups({
    order_by: 'name'
  })

  const columns = useMemo(
    () => [
      {
        Header: 'name',
        accessor: 'name',
        width: '90%',
        Cell: ({
          row: {
            original: { name, foods_count: foodsCount }
          }
        }) => (
          <>
            <MDTypography variant="body2" fontWeight="bold">
              {name}
            </MDTypography>
            {/* <MDTypography variant="caption">{foodsCount} Foods</MDTypography> */}
          </>
        )
      },
      {
        Header: 'Actions',
        sorting: false,
        width: '10%',
        Cell: ({
          row: {
            original: { id }
          }
        }) => (
          <>
            <EditFoodGroupButton id={id} />
            <DeleteFoodGroupButton id={id} />
          </>
        )
      }
    ],
    []
  )

  return (
    <>
      {isLoading && <ListLoader />}

      {!!data?.data?.results && (
        <DataTable
          canSearch
          table={{
            columns: columns,
            rows: data.data.results
          }}
        />
      )}
    </>
  )
}

export default FoodGroupsTable
