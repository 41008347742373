import { useMaterialUIController } from '@/context'
import MDBox from '@/components/MDBox'
import Grid from '@mui/material/Grid'
import MDTypography from '@/components/MDTypography'
import Icon from '@mui/material/Icon'
import dayjs from 'dayjs'
import EditScheduledMealButton from '@/features/diet_plan/buttons/EditScheduledMealButton'
import AddMealButton from '@/features/diet_plan/buttons/AddMealButton'
import EditMealButton from '@/features/diet_plan/buttons/EditMealButton'
import DeleteScheduledMealButton from '@/features/diet_plan/buttons/DeleteScheduledPlanButton'
import DeleteMealButton from '@/features/diet_plan/buttons/DeleteMealButton'
import Card from '@mui/material/Card'
import { round } from 'lodash'
import { grey } from '@mui/material/colors'

function ColumnCard({ dragging, data }) {
  const [controller] = useMaterialUIController()
  const { darkMode } = controller

  const mealNameMap = {
    breakfast: 'Breakfast',
    lunch: 'Lunch',
    dinner: 'Dinner',
    snack: 'Snack'
  }
  return (
    <Card
      dragging={dragging.toString() || undefined}
      sx={{
        overflow: 'hidden',
        width: 'calc(300px)',
        mb: 2.5,
        lineHeigh: 1.5,
        mx: 3
      }}>
      <Grid
        container
        sx={{ px: 2, py: 1, borderBottom: `1px solid ${grey[200]}` }}>
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
          <MDTypography variant="button" fontWeight="medium">
            {mealNameMap[data.meal_name]}
          </MDTypography>

          <MDTypography
            fontWeight="regular"
            variant="button"
            sx={{
              ml: 'auto',
              mr: 1,
              display: 'flex',
              alignItems: 'center'
            }}>
            <Icon sx={{ mr: 1 }}>access_time</Icon>
            {dayjs(data?.time, 'HH:mm:ss').format('hh:mm A')}
          </MDTypography>
          <EditScheduledMealButton scheduledMealId={data.id} />
          <DeleteScheduledMealButton id={data.id} sx={{ ml: 1 }} />
        </Grid>
      </Grid>
      <Grid container sx={{ borderBottom: `1px solid ${grey[200]}` }}>
        <Grid item xs={12}>
          {data.components.map((meal) => (
            <MDBox
              key={meal.id}
              sx={{
                display: 'flex',
                px: 2,
                mt: 1
              }}>
              <MDTypography variant="body2">{meal.recipe.name}</MDTypography>
              <MDTypography variant="body2" sx={{ ml: 'auto' }}>
                {meal.quantity}
                {meal.unit}
              </MDTypography>
              <EditMealButton mealId={meal.id} />
              <DeleteMealButton id={meal.id} />
            </MDBox>
          ))}
          {data.components.length === 0 && (
            <MDTypography variant="body2" sx={{ px: 2 }}>
              No meals added
            </MDTypography>
          )}
        </Grid>
        <Grid item xs={12}>
          <AddMealButton scheduledMealId={data.id} />
        </Grid>
      </Grid>
      <Grid container sx={{ backgroundColor: grey[100] }}>
        <Grid item xs={12}>
          {data.compositions.map((composition) => (
            <MDBox
              key={composition.id}
              sx={{ display: 'flex' }}
              px={2}
              mb={1}
              mt={1}>
              <MDTypography variant="caption">
                {composition.nutrient.name}
              </MDTypography>
              <MDTypography variant="caption" sx={{ ml: 'auto' }}>
                {round(composition.quantity, 2)}
                {composition.nutrient.unit}
              </MDTypography>
            </MDBox>
          ))}
        </Grid>
      </Grid>
    </Card>
  )
}

export default ColumnCard
