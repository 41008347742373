import { CircularProgress } from '@mui/material'
import React from 'react'
import MDBox from '../MDBox'

function ListLoader() {
  return (
    <MDBox display="flex" justifyContent="center" width="100%" p={4}>
      <CircularProgress />
    </MDBox>
  )
}

export default ListLoader
