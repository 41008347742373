import DialogFormButton from '@/components/DialogFormButton'
import MDButton from '@/components/MDButton'
import { Icon } from '@mui/material'
import React from 'react'
import FoodGroupForm from '../forms/FoodGroupForm'

function AddFoodGroupButton({ id, ...props }) {
  return (
    <DialogFormButton
      title="Add Composition"
      renderButton={({ onClick }) => (
        <MDButton
          onClick={onClick}
          variant="gradient"
          color="info"
          size="small"
          startIcon={<Icon>add</Icon>}
          {...props}>
          Add Composition
        </MDButton>
      )}
      renderForm={(props) => <FoodGroupForm ingredientId={id} {...props} />}
    />
  )
}

export default AddFoodGroupButton
