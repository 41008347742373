import DialogFormButton from '@/components/DialogFormButton'
import MDButton from '@/components/MDButton'
import { Icon } from '@mui/material'
import React from 'react'
import ScheduledMealForm from '../forms/ScheduledMealForm'
import Tooltip from '@mui/material/Tooltip'

function EditScheduledMealButton({ scheduledMealId, dietPlanId, ...props }) {
  return (
    <DialogFormButton
      title="Edit Scheduled Meal"
      renderButton={({ onClick }) => (
        <Tooltip title="Edit Meal" arrow>
          <MDButton
            onClick={onClick}
            variant="outlined"
            color="info"
            size="small"
            iconOnly
            {...props}>
            <Icon>edits</Icon>
          </MDButton>
        </Tooltip>
      )}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
      renderForm={(props) => (
        <ScheduledMealForm
          scheduledMealId={scheduledMealId}
          dietPlanId={dietPlanId}
          {...props}
        />
      )}
    />
  )
}

export default EditScheduledMealButton
