import { useField } from 'formik'
import React from 'react'
import { DatePicker } from '@mui/x-date-pickers'
import TextField from '@mui/material/TextField'

function FormikDatePicker({
  name,
  label,
  type = 'text',
  defaultCountry,
  ...props
}) {
  const [, { value, error, touched }, { setValue, setTouched }] = useField({
    name,
    label
  })
  // const { handleBlur } = useFormikContext()

  const renderInput = (props) => (
    <TextField type="text" size="small" variant="outlined" {...props} />
  )

  return (
    <DatePicker
      name={name}
      label={label}
      value={value}
      onChange={(value) => {
        console.log('value', value)
        setValue(value.format('YYYY-MM-DD'))
      }}
      onBlur={() => {
        setTouched(true)
      }}
      helperText={error && touched && error}
      error={error && touched}
      inputVariant="outlined"
      TextFieldComponent={renderInput}
      {...props}
    />
  )
}

export default FormikDatePicker
