import DialogFormButton from '@/components/DialogFormButton'
import MDButton from '@/components/MDButton'
import { Icon } from '@mui/material'
import React from 'react'
import DietPlanForm from '../forms/DietPlanForm'

function AddDietPlanButton({ foodItemId, ...props }) {
  return (
    <DialogFormButton
      title="Add Diet Plan"
      renderButton={({ onClick }) => (
        <MDButton
          onClick={onClick}
          variant="gradient"
          color="info"
          size="small"
          startIcon={<Icon>add</Icon>}
          {...props}>
          Add Diet Plan
        </MDButton>
      )}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
      renderForm={(props) => (
        <DietPlanForm foodItemId={foodItemId} {...props} />
      )}
    />
  )
}

export default AddDietPlanButton
