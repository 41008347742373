import { useMutation, useQuery } from '@tanstack/react-query'
import DietApi from '@/services/admin_api'
import queryClient from '@/query/client'

function useDietPlanQuery() {
  function getDietPlans(params) {
    return useQuery({
      queryKey: ['diet_plan', 'list'],
      cacheTime: 1000 * 60 * 60,
      staleTime: 1000 * 60,
      queryFn: () => DietApi.DietPlan.get({ ...params, limit: 1000 })
    })
  }

  function getDietPlan({ id }) {
    return useQuery({
      queryKey: ['diet_plan', id],
      enabled: !!id,
      queryFn: () => DietApi.DietPlan.getOne({ id })
    })
  }

  function createDietPlan() {
    return useMutation({
      mutationFn: async ({ data }) => DietApi.DietPlan.create({ data }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: ['diet_plan'] })
    })
  }

  function updateDietPlan() {
    return useMutation({
      mutationFn: async ({ id, data }) => DietApi.DietPlan.update({ id, data }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: ['diet_plan'] })
    })
  }

  function deleteDietPlan() {
    return useMutation({
      mutationFn: async ({ id }) => DietApi.DietPlan.delete({ id }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: ['diet_plan'] })
    })
  }

  return {
    getDietPlans,
    getDietPlan,
    createDietPlan,
    updateDietPlan,
    deleteDietPlan
  }
}

export default useDietPlanQuery
