import { useMutation, useQuery } from '@tanstack/react-query'
import DietApi from '@/services/admin_api'
import queryClient from '@/query/client'

function useScheduledMealQuery() {
  function getScheduledMeals(params) {
    return useQuery({
      queryKey: ['scheduled_meal', 'list'],
      cacheTime: 1000 * 60 * 60,
      staleTime: 1000 * 60,
      queryFn: () => DietApi.ScheduledMeal.get({ ...params, limit: 1000 })
    })
  }

  function getScheduledMeal({ id }) {
    return useQuery({
      queryKey: ['scheduled_meal', id],
      enabled: !!id,
      queryFn: () => DietApi.ScheduledMeal.getOne({ id })
    })
  }

  function createScheduledMeal() {
    return useMutation({
      mutationFn: async ({ data }) => DietApi.ScheduledMeal.create({ data }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['scheduled_meal'] })
        queryClient.invalidateQueries({ queryKey: ['diet_plan'] })
      }
    })
  }

  function updateScheduledMeal() {
    return useMutation({
      mutationFn: async ({ id, data }) =>
        DietApi.ScheduledMeal.update({ id, data }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['scheduled_meal'] })
        queryClient.invalidateQueries({ queryKey: ['diet_plan'] })
      }
    })
  }

  function deleteScheduledMeal() {
    return useMutation({
      mutationFn: async ({ id }) => DietApi.ScheduledMeal.delete({ id }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['scheduled_meal'] })
        queryClient.invalidateQueries({ queryKey: ['diet_plan'] })
      }
    })
  }

  return {
    getScheduledMeals,
    getScheduledMeal,
    createScheduledMeal,
    updateScheduledMeal,
    deleteScheduledMeal
  }
}

export default useScheduledMealQuery
