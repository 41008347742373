import DialogFormButton from '@/components/DialogFormButton'
import TableRowButton from '@/components/Table/TableRowButton'
import { Icon } from '@mui/material'
import FoodGroupForm from '../forms/FoodGroupForm'

function EditFoodGroupButton({ id, ...parentProps }) {
  return (
    <DialogFormButton
      title="Edit Food Group"
      renderButton={(props) => (
        <TableRowButton
          tooltip="Edit"
          sx={{ marginRight: 2 }}
          {...props}
          {...parentProps}>
          <Icon>edit</Icon>
        </TableRowButton>
      )}
      renderForm={(props) => <FoodGroupForm foodGroupId={id} {...props} />}
    />
  )
}

export default EditFoodGroupButton
