import { useConfirmationDialog } from '@/components/Confirmation/hooks'
import TableRowButton from '@/components/Table/TableRowButton'

import { Icon } from '@mui/material'
import useFoodGroupQuery from '../query/foodGroupQuery'

function DeleteFoodGroupButton({ id }) {
  const { deleteFoodGroup } = useFoodGroupQuery()
  const { mutateAsync: deleteGroup, isLoading } = deleteFoodGroup()
  const { getConfirmation } = useConfirmationDialog()

  const onClick = async () => {
    const confirmed = await getConfirmation({
      title: 'Delete Food Group',
      message: 'Are you sure you want to delete this food group?',
      confirmText: 'Delete'
    })
    if (confirmed) deleteGroup({ id })
  }

  return (
    <TableRowButton
      onClick={onClick}
      disabled={isLoading}
      tooltip="Delete"
      variant="gradient"
      color="light">
      <Icon>delete</Icon>
    </TableRowButton>
  )
}

export default DeleteFoodGroupButton
