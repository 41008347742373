import { useMutation, useQuery } from '@tanstack/react-query'
import queryClient from '@/query/client'
import DietApi from '@/services/admin_api'

function useRecipeIngredientQuery() {
  function getRecipeIngredients(params) {
    return useQuery({
      queryKey: ['recipeIngredient', 'list'],
      cacheTime: 1000 * 60 * 60,
      staleTime: 1000 * 60,
      queryFn: () => DietApi.RecipeIngredient.get({ ...params, limit: 1000 })
    })
  }

  function getRecipeIngredient({ id }) {
    return useQuery({
      queryKey: ['recipeIngredient', id],
      enabled: !!id,
      queryFn: () => DietApi.RecipeIngredient.getOne({ id })
    })
  }

  function createRecipeIngredient() {
    return useMutation({
      mutationFn: async ({ data }) => DietApi.RecipeIngredient.create({ data }),
      onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: ['recipeIngredient'] })
        queryClient.invalidateQueries({
          queryKey: ['composition', 'list', `recipe_${data.data.recipe}`]
        })
      }
    })
  }

  function updateRecipeIngredient() {
    return useMutation({
      mutationFn: async ({ id, data }) =>
        DietApi.RecipeIngredient.update({ id, data }),
      onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: ['recipeIngredient'] })
        queryClient.invalidateQueries({
          queryKey: ['composition', 'list', `recipe_${data.data.recipe}`]
        })
      }
    })
  }

  function deleteRecipeIngredient() {
    return useMutation({
      mutationFn: async ({ id }) => DietApi.RecipeIngredient.delete({ id }),
      onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: ['recipeIngredient'] })
        queryClient.invalidateQueries({
          queryKey: ['composition', 'list', `recipe_${data.data.recipe}`]
        })
      }
    })
  }

  return {
    getRecipeIngredients,
    getRecipeIngredient,
    createRecipeIngredient,
    updateRecipeIngredient,
    deleteRecipeIngredient
  }
}

export default useRecipeIngredientQuery
