import { useField } from 'formik'
import React from 'react'
import { TimePicker } from '@mui/x-date-pickers'
import MDInput from '@/components/MDInput'
import dayjs from 'dayjs'

function FormikTimePicker({
  name,
  label,
  type = 'text',
  defaultCountry,
  ...props
}) {
  const [, { value, error, touched }, { setValue, setTouched }] = useField({
    name,
    label
  })
  // const { handleBlur } = useFormikContext()

  const renderInput = (props) => (
    <MDInput type="text" size="small" variant="outlined" {...props} fullWidth />
  )

  return (
    <TimePicker
      name={name}
      label={label}
      value={dayjs(value, 'HH:mm:ss')}
      onChange={(v) => {
        console.log('onChange')
        console.log(v)
        setValue(v.format('HH:mm:ss'))
      }}
      onBlur={() => {
        setTouched(true)
      }}
      helperText={error && touched && error}
      error={error && touched}
      inputVariant="outlined"
      TextFieldComponent={renderInput}
      {...props}
    />
  )
}

export default FormikTimePicker
