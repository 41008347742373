import { useEffect, useState } from 'react'
import Board, { moveCard } from '@asseinfo/react-kanban'
import useScheduledMealQuery from '@/features/diet_plan/query/scheduled_meal'
import ColumnHeader from '@/features/diet_plan/components/DietPlanner/ColumnHeader'
import ColumnCard from '@/features/diet_plan/components/DietPlanner/ColumnCard'

function DietPlannerBoard({ dietPlan }) {
  const { getScheduledMeals, updateScheduledMeal } = useScheduledMealQuery()
  const { mutateAsync: update } = updateScheduledMeal()
  const { data, isLoading } = getScheduledMeals({
    diet_plan: dietPlan.id,
    ordering: 'time'
  })

  const [board, setBoard] = useState({
    columns: [
      {
        id: 1,
        title: 'Day 1',
        cards: []
      }
    ]
  })

  function updateBoardWithMeals(meals) {
    const newBoard = {
      columns: []
    }
    //  create set of days
    const days = new Set()
    meals.forEach((meal) => {
      days.add(meal.day)
    })

    if (!days.size) days.add(1)

    //   iterate over set
    days.forEach((day) => {
      //   create new column
      const newColumn = {
        id: day,
        title: `Day ${day}`,
        cards: []
      }

      // Add cards
      meals.forEach((meal) => {
        if (meal.day === day) {
          const newCard = {
            id: meal.id,
            title: meal.meal_name,
            description: meal.meal_name,
            tags: [meal.meal_name],
            color: '#5E72E4',
            data: meal
          }
          newColumn.cards.push(newCard)
        }
      })

      // Add column to board
      newBoard.columns.push(newColumn)
    })

    setBoard(newBoard)
  }

  function addColumn() {
    const newColumn = {
      id: board.columns.length + 1,
      title: `Day ${board.columns.length + 1}`,
      cards: []
    }
    setBoard({
      ...board,
      columns: [...board.columns, newColumn]
    })
  }

  useEffect(() => {
    if (data) updateBoardWithMeals(data.data.results)
  }, [data])

  return (
    <Board
      // disableColumnDrag
      onCardDragEnd={(card, source, destination) => {
        setBoard(moveCard(board, source, destination))

        update({
          id: card.id,
          data: {
            day: destination?.toColumnId,
            diet_plan: dietPlan.id,
            meal_name: card.data.meal_name
          }
        })

        // moveCard(card, source, destination)
        console.log(card, source, destination)
      }}
      onColumnDragEnd={(column, source, destination) => {
        console.log(column, source, destination)
      }}
      renderCard={({ id, data }, { dragging }) => (
        <ColumnCard key={id} dragging={dragging} data={data} />
      )}
      renderColumnHeader={({ id, title }) => (
        <ColumnHeader
          key={id}
          id={id}
          title={title}
          addColumn={addColumn}
          isLastColumn={board.columns.length === id}
          dietPlan={dietPlan}
          scheduledMeals={data?.data?.results.filter((x) => x.day === id)}
        />
      )}>
      {board}
    </Board>
  )
}

export default DietPlannerBoard
