import { Icon } from '@mui/material'
import React, { useState } from 'react'
import TableRowButton from '../../../components/Table/TableRowButton'
import NutrientDialog from '../NutrientDialog'

function EditNutrientButton({ id }) {
  const [open, setOpen] = useState(false)
  return (
    <>
      <TableRowButton
        tooltip="Edit"
        onClick={() => setOpen(true)}
        sx={{ marginRight: 2 }}>
        <Icon>edit</Icon>
      </TableRowButton>
      <NutrientDialog id={id} open={open} onClose={() => setOpen(false)} />
    </>
  )
}

export default EditNutrientButton
