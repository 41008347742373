import { Form, Formik } from 'formik'
import React from 'react'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import FormikTextField from '../../../components/Formik/FormikTextField'
import LoadingButton from '../../../components/LoadingButton'
import useRecipeQuery from '../query/recipe'
import { InputAdornment } from '@mui/material'
import MDTypography from '@/components/MDTypography'
import FormikTagsField from '@/components/Formik/FormikTagsField'

function RecipeForm({ recipeId, foodItemId, onSuccess }) {
  const { getRecipe, createRecipe, updateRecipe } = useRecipeQuery()
  const { data: recipe, isLoading } = getRecipe({ id: recipeId })
  const { mutateAsync: updateGroup, isLoading: isUpdating } = updateRecipe()
  const { mutateAsync: createGroup, isLoading: isCreating } = createRecipe()

  const initialValues = {
    name: recipe?.data?.name || '',
    tags: recipe?.data?.tags || [],
    serving_size: recipe?.data?.serving_size || 0,
    food_item: foodItemId
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    serving_size: Yup.number().required('Required')
  })

  const onSubmit = async (values, { setErrors }) => {
    try {
      values = {
        ...values,
        food_item: foodItemId
      }
      if (recipeId) {
        await updateGroup({ id: recipeId, data: values })
        toast('Recipe updated', { type: 'success' })
      } else {
        await createGroup({ data: values })
        toast('Recipe created', { type: 'success' })
      }
      onSuccess()
    } catch (error) {
      setErrors(error.response.data)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize>
      {({ isSubmitting }) => (
        <Form id="formId" style={{ overflow: 'visible' }}>
          <FormikTextField
            name="name"
            fullWidth
            label="Recipe Name"
            sx={{ my: 1 }}
          />
          <FormikTextField
            type="number"
            name="serving_size"
            fullWidth
            label="Serving Size"
            sx={{ my: 1 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <MDTypography variant="subtitle2">g</MDTypography>
                </InputAdornment>
              )
            }}
          />
          <FormikTagsField name="tags" fullWidth label="Tags" sx={{ my: 1 }} />
          <LoadingButton
            type="submit"
            fullWidth
            size="large"
            loading={isSubmitting}
            sx={{ mt: 2 }}>
            {recipeId ? 'Update Recipe' : 'Create Recipe'}
          </LoadingButton>
        </Form>
      )}
    </Formik>
  )
}

export default RecipeForm
