import { useMutation, useQuery } from '@tanstack/react-query'
import queryClient from '../../../query/client'
import DietApi from '../../../services/admin_api'

function useFoodGroupQuery() {
  function getFoodGroups(params) {
    return useQuery({
      queryKey: ['group', 'list'],
      queryFn: () => DietApi.FoodGroup.get({ ...params, limit: 1000 })
    })
  }

  function getFoodGroup(id) {
    return useQuery({
      queryKey: ['group', id],
      enabled: !!id,
      queryFn: () => DietApi.FoodGroup.getOne({ id })
    })
  }

  function createFoodGroup() {
    return useMutation({
      mutationFn: ({ data }) => DietApi.FoodGroup.create({ data }),
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['group'] })
    })
  }

  function updateFoodGroup() {
    return useMutation({
      mutationFn: ({ id, data }) => DietApi.FoodGroup.update({ id, data }),
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['group'] })
    })
  }

  function deleteFoodGroup() {
    return useMutation({
      mutationFn: ({ id }) => DietApi.FoodGroup.delete({ id }),
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['group'] })
    })
  }

  return {
    getFoodGroups,
    getFoodGroup,
    createFoodGroup,
    updateFoodGroup,
    deleteFoodGroup
  }
}

export default useFoodGroupQuery
