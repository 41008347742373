import { Box, Icon, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

import EditRecipeButton from '@/features/recipe/buttons/EditRecipeButton'
import DeleteRecipeButton from '@/features/recipe/buttons/DeleteRecipeButton'

function RecipeCard({ recipe }) {
  return (
    <Box display="flex">
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 400 }}>
          {recipe.food_item.name}
        </Typography>
        <Typography variant="h4" sx={{ fontWeight: 600, mb: 1 }}>
          {recipe.name}
        </Typography>
        {recipe.tags?.length > 0 && (
          <Typography
            variant="subtitle2"
            sx={{
              color: grey[500],
              display: 'flex',
              alignItems: 'center',
              mb: 1
            }}>
            <Icon sx={{ mr: 1 }}>local_offer</Icon>
            {recipe.tags.map((tag) => (
              <Box
                key={tag}
                label={tag}
                sx={{
                  px: 1,
                  backgroundColor: grey[400],
                  color: '#fff',
                  borderRadius: 1,
                  mr: 1,
                  fontSize: '0.8rem'
                }}>
                {tag}
              </Box>
            ))}
          </Typography>
        )}
        <Typography
          variant="subtitle2"
          sx={{ color: grey[500], display: 'flex', alignItems: 'center' }}>
          <Icon sx={{ mr: 1 }}>format_color_fill</Icon>
          {recipe.serving_size}g
        </Typography>
      </Box>
      <Box sx={{ ml: 'auto' }}>
        <EditRecipeButton
          id={recipe.id}
          foodItemId={recipe.food_item}
          sx={{ mr: 1 }}
        />
        <DeleteRecipeButton id={recipe.id} />
      </Box>
    </Box>
  )
}

export default RecipeCard
