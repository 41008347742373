import { Box, Chip, Icon, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

import React from 'react'
import DeleteFoodItemButton from '../buttons/DeleteFoodItemButton'
import EditFoodItemButton from '../buttons/EditFoodItemButton'

function FoodDetailCard({ food }) {
  return (
    <Box display="flex">
      <Box>
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          {food.name}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ color: grey[500], display: 'flex', alignItems: 'center' }}>
          <Icon sx={{ mr: 1, mb: 1 }}>local_offer</Icon>
          {food?.tags?.join(', ')}
        </Typography>
        {food.vegetarian ? (
          <Chip
            color="success"
            label="Vegetarian"
            size="small"
            sx={{ color: 'white' }}
          />
        ) : (
          <Chip color="warning" label="Non-Vegetarian" size="small" />
        )}
      </Box>
      <Box sx={{ ml: 'auto' }}>
        <EditFoodItemButton id={food.id} sx={{ mr: 1 }} />
        <DeleteFoodItemButton id={food.id} />
      </Box>
    </Box>
  )
}

export default FoodDetailCard
