import TableRowButton from '@/components/Table/TableRowButton'
import { Icon } from '@mui/material'
import useRecipeIngredientQuery from '@/features/recipe/query/recipe-ingredients'

function DeleteRecipeIngredientButton({ id, ...props }) {
  const { deleteRecipeIngredient } = useRecipeIngredientQuery()
  const { mutate } = deleteRecipeIngredient()

  return (
    <TableRowButton
      size="small"
      variant="outlined"
      color="error"
      onClick={() => mutate({ id })}
      {...props}>
      <Icon>delete</Icon>
    </TableRowButton>
  )
}

export default DeleteRecipeIngredientButton
