import { Tooltip } from "@mui/material"
import React from "react"
import MDButton from "../MDButton"

const TableRowButton = React.forwardRef(
  ({ children, tooltip, ...props }, ref) => (
    <Tooltip title={tooltip}>
      <MDButton
        ref={ref}
        variant="gradient"
        color="primary"
        size="small"
        iconOnly
        {...props}
      >
        {children}
      </MDButton>
    </Tooltip>
  )
)

export default TableRowButton
