import { FormHelperText, Grid } from "@mui/material"
import { useFormikContext } from "formik"

function FormikNonFieldError() {
  const { errors } = useFormikContext()

  if (errors?.non_field_errors || errors?.detail) {
    return (
      <Grid xs={12} item>
        {errors.non_field_errors && (
          <FormHelperText error>{errors.non_field_errors}</FormHelperText>
        )}
        {errors.detail && (
          <FormHelperText error>{errors.detail}</FormHelperText>
        )}
      </Grid>
    )
  }
  return null
}

export default FormikNonFieldError
