import DialogFormButton from '@/components/DialogFormButton'
import { Icon } from '@mui/material'
import React from 'react'
import MDButton from '../../../components/MDButton'
import AppointmentSlotForm from '@/features/appointments/forms/AppointmentSlotForm'

function AddAppointmentButton({
  ingredientId,
  foodId,
  disabled = false,
  ...props
}) {
  return (
    <DialogFormButton
      title="Add Slot"
      renderButton={({ onClick }) => (
        <MDButton
          onClick={onClick}
          variant="gradient"
          color="primary"
          startIcon={<Icon>add</Icon>}
          disabled={disabled}
          {...props}>
          Add Slot
        </MDButton>
      )}
      renderForm={(props) => (
        <AppointmentSlotForm
          ingredientId={ingredientId}
          foodId={foodId}
          {...props}
        />
      )}
    />
  )
}

export default AddAppointmentButton
