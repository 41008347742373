import MDBox from '../MDBox'
import MDTypography from '../MDTypography'

function PageHeader({ title, description, actions, children, ...props }) {
  return (
    <>
      <MDBox mt={3} mb={2} display="flex" alignItems="center" {...props}>
        {(title || description) && (
          <MDBox>
            {title && (
              <MDBox mb={1}>
                <MDTypography variant="h5">{title}</MDTypography>
              </MDBox>
            )}
            {description && (
              <MDBox>
                <MDTypography variant="body2" color="text">
                  {description}
                </MDTypography>
              </MDBox>
            )}
          </MDBox>
        )}

        {actions && <MDBox ml="auto">{actions()}</MDBox>}
      </MDBox>
      {children}
    </>
  )
}

export default PageHeader
