import { useMutation, useQuery } from '@tanstack/react-query'
import queryClient from '@/query/client'
import DietAdminApi from '@/services/admin_api'

function useCompositionQuery() {
  function getCompositions(params, enabled = true) {
    const { ingredient, recipe } = params

    const key = ingredient ? `ingredient_${ingredient}` : `recipe_${recipe}`

    return useQuery({
      queryKey: ['composition', 'list', key, params],
      enabled: !!(enabled && (recipe || ingredient)),
      queryFn: () =>
        DietAdminApi.Composition.get({
          ...params,
          limit: 1000
        })
    })
  }

  function getComposition({ id, parent = 'ingredient' }) {
    return useQuery({
      queryKey: ['composition', id],
      enabled: !!id,
      queryFn: () => DietAdminApi.Composition.getOne({ id })
    })
  }

  function createComposition() {
    return useMutation({
      mutationFn: ({ data }) => DietAdminApi.Composition.create({ data }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: ['composition'] })
    })
  }

  function updateComposition() {
    return useMutation({
      mutationFn: ({ id, data }) =>
        DietAdminApi.Composition.update({ id, data }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: ['composition'] })
    })
  }

  function deleteComposition() {
    return useMutation({
      mutationFn: ({ id }) => DietAdminApi.Composition.delete({ id }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: ['composition'] })
    })
  }

  return {
    getCompositions,
    getComposition,
    createComposition,
    updateComposition,
    deleteComposition
  }
}

export default useCompositionQuery
