// import ConfirmationDialog from "./ConfirmationDialog";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide
} from '@mui/material'
import { createContext, forwardRef, useMemo, useState } from 'react'
import MDButton from '../MDButton'

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
))

function ConfirmationDialog({
  open,
  title,
  message,
  onConfirm,
  onDismiss,
  confirmText,
  dismissText,
  showDismiss = false,
  confirmBtnProps = {}
}) {
  return (
    <Dialog open={open} onClose={onDismiss} TransitionComponent={Transition}>
      <DialogTitle>{title}</DialogTitle>
      {/* {!dismissText && <DialogCloseButton onClick={() => onDismiss()} />} */}
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {showDismiss && (
          <MDButton onClick={onDismiss}>{dismissText || 'Cancel'}</MDButton>
        )}
        <MDButton
          color="info"
          variant="contained"
          onClick={onConfirm}
          {...confirmBtnProps}>
          {confirmText || 'Confirm'}
        </MDButton>
      </DialogActions>
    </Dialog>
  )
}

const ConfirmationDialogContext = createContext({})

function ConfirmationDialogProvider({ children }) {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogConfig, setDialogConfig] = useState({})
  const { actionCallback } = dialogConfig

  const openDialog = (props) => {
    setDialogOpen(true)
    setDialogConfig(props)
  }

  const resetDialog = () => {
    setDialogOpen(false)
  }

  const onConfirm = () => {
    resetDialog()
    actionCallback(true)
    setTimeout(() => {
      setDialogConfig({})
    }, 1000)
  }

  const onDismiss = () => {
    resetDialog()
    actionCallback(false)
  }

  return (
    <ConfirmationDialogContext.Provider
      value={useMemo(() => ({ openDialog: openDialog }), [])}>
      <ConfirmationDialog
        open={dialogOpen}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
        {...dialogConfig}
      />
      {children}
    </ConfirmationDialogContext.Provider>
  )
}

export default ConfirmationDialog
export { ConfirmationDialogProvider, ConfirmationDialogContext }
