import GenericList from '@/components/List/GenericList'
import MDTypography from '@/components/MDTypography'
import { Grid } from '@mui/material'
import { grey } from '@mui/material/colors'
import React, { forwardRef, useEffect } from 'react'
import DeleteRecipeIngredientButton from '../buttons/DeleteRecipeIngredientButton'
import EditRecipeIngredientButton from '../buttons/EditRecipeIngredientButton'
import useRecipeIngredientQuery from '@/features/recipe/query/recipe-ingredients'

function RecipeIngredientList({ recipeId, setCount }) {
  const { getRecipeIngredients } = useRecipeIngredientQuery()
  const { data, isLoading } = getRecipeIngredients({
    recipe: recipeId,
    expand: 'ingredient'
  })

  useEffect(() => {
    setCount?.(data?.data?.count)
  }, [data])

  return (
    <GenericList
      items={data?.data?.results}
      loading={isLoading}
      emptyMessage="No ingredients added yet"
      renderItem={(item) => (
        <RecipeIngredientItem item={item} recipeId={recipeId} key={item.id} />
      )}
    />
  )
}

const RecipeIngredientItem = forwardRef(({ item, recipeId }, ref) => (
  <Grid container sx={{ px: 3, py: 2, borderBottom: `1px solid ${grey[200]}` }}>
    <Grid item xs={6}>
      <MDTypography variant="body2">{item.ingredient.name}</MDTypography>
    </Grid>
    <Grid item xs={4}>
      <MDTypography variant="body2" sx={{ textAlign: 'right', mr: 3 }}>
        {item.quantity}g
      </MDTypography>
    </Grid>
    <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <EditRecipeIngredientButton
        id={item.id}
        recipeId={recipeId}
        sx={{ mr: 1 }}
      />
      <DeleteRecipeIngredientButton id={item.id} />
    </Grid>
  </Grid>
))

export default RecipeIngredientList
