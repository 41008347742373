import { useConfirmationDialog } from '@/components/Confirmation/hooks'
import MDButton from '@/components/MDButton'
import useFoodItemQuery from '@/query/foodItem'
import { Icon } from '@mui/material'

function DeleteFoodItemButton({ id, ...props }) {
  const { deleteFoodItem } = useFoodItemQuery()
  const { mutate } = deleteFoodItem()

  const { getConfirmation } = useConfirmationDialog()

  const onClick = () => {
    if (
      getConfirmation({
        title: 'Delete Food Item',
        message: 'Are you sure you want to delete this food item?',
        confirmText: 'Delete'
      })
    ) {
      mutate({ id })
    }
  }

  return (
    <MDButton
      size="small"
      variant="outlined"
      color="error"
      onClick={onClick}
      startIcon={<Icon>delete</Icon>}
      {...props}>
      Delete
    </MDButton>
  )
}

export default DeleteFoodItemButton
