import { useEffect } from 'react'

// react-router-dom components
import { useLocation } from 'react-router-dom'

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

// Material Dashboard 2 PRO React components
import { setLayout, useMaterialUIController } from 'context'
import MDBox from '@/components/MDBox'
import Container from '@mui/material/Container'

// Material Dashboard 2 PRO React context

function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController()
  const { miniSidenav } = controller
  const { pathname } = useLocation()

  useEffect(() => {
    setLayout(dispatch, 'dashboard')
  }, [pathname])

  return (
    <Container maxWidth="lg" disableGutters>
      <MDBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          // px: 3,
          position: 'relative',

          [breakpoints.up('xl')]: {
            marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
            transition: transitions.create(['margin-left', 'margin-right'], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard
            })
          }
        })}>
        {children}
      </MDBox>
    </Container>
  )
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default DashboardLayout
