import { useField, useFormikContext } from 'formik'
import React from 'react'
import MDInput from '../MDInput'

function FormikTextField({ name, label, type = 'text', ...props }) {
  const [, { value, error, touched }, { setValue }] = useField({
    name,
    label
  })
  const { handleBlur } = useFormikContext()

  // format error
  let formattedError = error
  if (Array.isArray(error)) {
    formattedError = (
      <>
        {error.map((l, i) => (
          <div key={i}>
            {l} <br />
          </div>
        ))}
      </>
    )
  }

  return (
    <MDInput
      name={name}
      label={label}
      type={type}
      value={value}
      onChange={(e) => {
        setValue(e.target.value)
      }}
      onBlur={handleBlur}
      helperText={error && touched && formattedError}
      error={!!error}
      {...props}
    />
  )
}
export default FormikTextField
