import { Icon } from '@mui/material'
import React, { useState } from 'react'
import MDBox from '../../../components/MDBox'
import MDButton from '../../../components/MDButton'
import NutrientDialog from '../NutrientDialog'

function AddNutrientButton() {
  const [open, setOpen] = useState(false)
  return (
    <>
      <MDButton
        variant="gradient"
        color="info"
        size="small"
        onClick={() => setOpen(true)}>
        <MDBox sx={{ display: 'flex', alignItems: 'center', color: 'inherit' }}>
          <Icon>add</Icon> Add Nutrient
        </MDBox>
      </MDButton>
      <NutrientDialog open={open} onClose={() => setOpen(false)} />
    </>
  )
}

export default AddNutrientButton
