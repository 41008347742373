import { useConfirmationDialog } from '@/components/Confirmation/hooks'
import TableRowButton from '@/components/Table/TableRowButton'
import { Icon } from '@mui/material'
import React from 'react'
import useCompositionQuery from '@/features/composition/query/composition'

function DeleteCompositionButton({ id }) {
  const { deleteComposition } = useCompositionQuery()
  const { mutateAsync: deleteItem } = deleteComposition()
  const { getConfirmation } = useConfirmationDialog()

  const onClick = async () => {
    if (
      await getConfirmation({
        title: 'Delete Composition',
        message: 'Are you sure you want to delete this composition?',
        confirmText: 'Delete'
      })
    ) {
      deleteItem({ id })
    }
  }

  return (
    <TableRowButton tootlip="Delete" onClick={onClick}>
      <Icon>delete</Icon>
    </TableRowButton>
  )
}

export default DeleteCompositionButton
