import DialogFormButton from '@/components/DialogFormButton'
import MDButton from '@/components/MDButton'
import { Icon } from '@mui/material'

import FoodItemForm from '../forms/FoodItemForm'

function AddFoodItemButton({ id, ...props }) {
  return (
    <DialogFormButton
      title="Create Food Item"
      renderButton={({ onClick }) => (
        <MDButton
          variant="gradient"
          size="small"
          color="info"
          onClick={onClick}>
          <Icon>add</Icon> Add New
        </MDButton>
      )}
      renderForm={({ onSuccess }) => <FoodItemForm onSuccess={onSuccess} />}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    />
  )
}

export default AddFoodItemButton
