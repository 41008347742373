import { Form, Formik } from 'formik'
import React from 'react'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import FormikTextField from '../../../components/Formik/FormikTextField'
import LoadingButton from '../../../components/LoadingButton'
import useScheduledMealQuery from '@/features/diet_plan/query/scheduled_meal'
import Grid from '@mui/material/Grid'
import FormikTimePicker from '@/components/Formik/FormikTimePicker'
import FormikAutocomplete from '@/components/Formik/FormikAutocomplete'

function ScehduledMealForm({
  scheduledMealId,
  dietPlanId,
  defaultDay,
  onSuccess
}) {
  const { getScheduledMeal, updateScheduledMeal, createScheduledMeal } =
    useScheduledMealQuery()
  const { data, isLoading } = getScheduledMeal({ id: scheduledMealId })
  const { mutateAsync: update, isLoading: isUpdating } = updateScheduledMeal()
  const { mutateAsync: create, isLoading: isCreating } = createScheduledMeal()

  const scehduledMeal = data?.data

  const initialValues = {
    meal_name: scehduledMeal?.meal_name || 'lunch',
    day: scehduledMeal?.day || defaultDay || 1,
    time: scehduledMeal?.time || '',
    diet_plan: scehduledMeal?.diet_plan || dietPlanId || ''
  }

  const validationSchema = Yup.object().shape({
    meal_name: Yup.string().required('Required'),
    day: Yup.number().required('Required'),
    time: Yup.string().required('Required')
  })

  const onSubmit = async (values, { setErrors }) => {
    console.log('onSubmit')
    console.log(values)
    try {
      if (scheduledMealId) {
        await update({
          id: scheduledMealId,
          data: values
        })
        toast('Schedule updated', { type: 'success' })
      } else {
        await create({ data: values })
        toast('Schedule created', { type: 'success' })
      }
      onSuccess()
    } catch (error) {
      setErrors(error.response.data)
    }
  }

  const mealNameOptions = [
    { value: 'breakfast', label: 'Breakfast' },
    { value: 'lunch', label: 'Lunch' },
    { value: 'dinner', label: 'Dinner' },
    { value: 'snack', label: 'Snack' }
  ]

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize>
      {({ isSubmitting }) => (
        <Form id="formId" style={{ overflow: 'visible' }}>
          <Grid container>
            <Grid item xs={12}>
              <FormikAutocomplete
                name="meal_name"
                label="Meal Name"
                fullWidth
                options={mealNameOptions.map((o) => o.value)}
                getOptionLabel={(option) =>
                  mealNameOptions.find((o) => o.value === option)?.label
                }
              />
            </Grid>

            <Grid item xs={6}>
              <FormikTextField
                name="day"
                fullWidth
                label="Day"
                sx={{ my: 1 }}
              />
            </Grid>

            <Grid item xs={6}>
              <FormikTimePicker
                name="time"
                fullWidth
                label="Time"
                sx={{ my: 1 }}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <LoadingButton
                type="submit"
                fullWidth
                size="large"
                loading={isSubmitting}
                sx={{ mt: 2 }}>
                {scheduledMealId ? 'Update Meal' : 'Create Meal'}
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default ScehduledMealForm
