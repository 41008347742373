import FormikNonFieldError from '@/components/Formik/FormikNFError'
import { Grid, Icon } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import LoadingButton from '../../../components/LoadingButton'
import MDBox from '../../../components/MDBox'
import useAppointmentQuery from '@/features/appointments/query/appointment'
import ClientSelect from '@/features/client/inputs/ClientSelect'
import MDTypography from '@/components/MDTypography'
import dayjs from 'dayjs'

function AppointmentBookForm({ slotId, onSuccess }) {
  const { getAppointmentSlot, updateAppointmentSlot, bookAppointmentSlot } =
    useAppointmentQuery()
  const { data, isLoading } = getAppointmentSlot({
    id: slotId,
    disable: !slotId
  })
  const slot = data?.data
  const { mutateAsync: bookAppointment } = bookAppointmentSlot()
  const { mutateAsync: update } = updateAppointmentSlot()

  const initialValues = {
    client_id: slot?.client || ''
  }

  const validationSchema = Yup.object().shape({
    client_id: Yup.number().required('Required')
  })

  const onSubmit = async (values, { setErrors }) => {
    try {
      let res
      if (slot) {
        res = await bookAppointment({ id: slot.id, data: values })
      }
      toast('Appointment Booked', { type: 'success' })
      onSuccess?.(res?.data)
    } catch (error) {
      setErrors(error?.response?.data)
      toast('Error booking appointment', { type: 'error' })
    }
  }

  console.log('slot', slot)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize>
      {({ isSubmitting, values }) => (
        <Form>
          <Grid container spacing={2}>
            {slot && (
              <Grid item xs={12}>
                <MDTypography variant="subtitle2">
                  {dayjs(slot.date).format('MMM DD, YYYY')}{' '}
                  {dayjs(slot.time, 'HH:MM:SS').format('hh:mm A')}
                </MDTypography>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormikNonFieldError />
            </Grid>

            <Grid item xs={12}>
              <MDBox display="flex" alignItems="end">
                <ClientSelect name="client_id" />
              </MDBox>
            </Grid>

            <Grid item xs={12} sx={{ alignItems: 'end' }}>
              <LoadingButton
                startIcon={<Icon>edit</Icon>}
                color="success"
                variant="gradient"
                size="small"
                type="submit"
                loading={isSubmitting}
                fullWidth>
                Book Appointment
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default AppointmentBookForm
