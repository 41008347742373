import { Box, Card, Chip, Grid, Typography } from '@mui/material'
import React, { forwardRef } from 'react'
import useAppointmentSlotQuery from '@/features/appointments/query/appointment'
import DashboardNavbar from '@/examples/Navbars/DashboardNavbar'
import PageHeader from '@/components/Layout/PageHeader'
import AddAppointmentSlotButton from '@/features/appointments/buttons/AddAppointmentSlotButton'
import DashboardLayout from '@/examples/LayoutContainers/DashboardLayout'
import dayjs from 'dayjs'
import AppointmentBookButton from '@/features/appointments/buttons/AppointmentBookButton'
import MDButton from '@/components/MDButton'
import Icon from '@mui/material/Icon'
import { useConfirmationDialog } from '@/components/Confirmation/hooks'
import TableRowButton from '@/components/Table/TableRowButton'
import { grey } from '@mui/material/colors'

function AppointmentSlotList({ onClick }) {
  const { getAppointmentSlots } = useAppointmentSlotQuery()
  const { data, isLoading } = getAppointmentSlots({ order_by: 'date,time' })
  const slots = data?.data.results
  const dates = slots?.reduce((acc, slot) => {
    if (!acc) acc = []
    console.log(slot.date)
    console.log(acc)
    if (!acc.includes(slot.date)) {
      acc.push(slot.date)
    }
    return acc
  }, [])
  return (
    <DashboardLayout>
      <DashboardNavbar
        title="AppointmentSlots"
        route={[{ label: 'AppointmentSlots', path: 'appointments' }]}
      />
      <PageHeader
        title="Appointment Slots"
        description="Use this page to add, update and delete appointment slots"
        actions={() => <AddAppointmentSlotButton />}
      />

      {dates?.map((date) => (
        <Card sx={{ mt: 2 }}>
          <Box key={date}>
            <Typography variant="h6" sx={{ p: 2 }}>
              {dayjs(date).format('MMM DD, YYYY')}
            </Typography>
            <Grid container>
              {slots
                ?.filter((slot) => slot.date === date)
                .map((slot) => (
                  <Grid key={slot.id} item xs={12} md={12}>
                    <AppointmentSlotItem slot={slot} onClick={onClick} />
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Card>
      ))}
    </DashboardLayout>
  )
}

const AppointmentSlotItem = forwardRef(({ slot }, ref) => {
  const { deleteAppointmentSlot, cancelAppointmentSlot } =
    useAppointmentSlotQuery()
  const { mutateAsync: deleteSlot } = deleteAppointmentSlot()
  const { mutateAsync: cancel } = cancelAppointmentSlot()
  const { getConfirmation } = useConfirmationDialog()

  return (
    <Box
      width="100%"
      p={2}
      display="flex"
      alignItems="center"
      borderTop={`1px solid ${grey[200]}`}>
      <Box display="flex" alignItem="center">
        <Box display="flex" alignItem="center">
          <Typography variant="body2" sx={{ fontWeight: 500, mr: 2 }}>
            {/* {dayjs(slot.date).format('MMM DD, YYYY')} at{' '} */}
            {dayjs(slot.time, 'HH:MM:SS').format('hh:mm A')}
          </Typography>
          <Chip
            size="small"
            label={slot.is_booked ? 'Booked' : 'Available'}
            color={slot.is_booked ? 'warning' : 'info'}
          />
        </Box>
        {slot.is_booked && (
          <Box display="flex" alignItem="center" ml={2}>
            <Icon>person</Icon>
            <Typography variant="body2" sx={{ fontWeight: 500, ml: 1 }}>
              {/* {dayjs(slot.date).format('MMM DD, YYYY')} at{' '} */}
              {slot?.appointments?.[0].client.user.name}
            </Typography>
          </Box>
        )}
      </Box>
      {/* <MDTypography variant="body2">{slot.duration_minutes} minutes</MDTypography> */}
      <Box ml="auto">
        {!slot.is_booked && <AppointmentBookButton slotId={slot.id} />}
        {slot.is_booked && (
          <MDButton
            size="small"
            variant="gradient"
            // color="default"
            // startIcon={<Icon>cross</Icon>}
            onClick={async () => {
              if (
                await getConfirmation({
                  title: 'Cancel Appointment',
                  message: 'Are you sure you want to cancel this appointment?',
                  confirmText: 'Cancel'
                })
              ) {
                await cancel({ id: slot.id })
              }
            }}>
            Cancel
          </MDButton>
        )}
        <TableRowButton
          sx={{ ml: 1 }}
          onClick={async () => {
            if (
              await getConfirmation({
                title: 'Delete Appointment Slot',
                message: 'Are you sure you want to cancel this slot?',
                confirmText: 'Delete'
              })
            ) {
              await deleteSlot({ id: slot.id })
            }
          }}
          color="error"
          variant="outlined"
          // onClick={onClick}
          tooltip="Delete Slot">
          <Icon>delete</Icon>
        </TableRowButton>
      </Box>
    </Box>
  )
})

export default AppointmentSlotList
