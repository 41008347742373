import { useConfirmationDialog } from '@/components/Confirmation/hooks'

import { Icon } from '@mui/material'
import MDButton from '@/components/MDButton'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import useScheduledMealQuery from '@/features/diet_plan/query/scheduled_meal'

function DeleteScheduledMealButton({ id, ...props }) {
  const { deleteScheduledMeal } = useScheduledMealQuery()
  const { mutateAsync: deleteItem, isLoading } = deleteScheduledMeal()
  const { getConfirmation } = useConfirmationDialog()

  const onClick = async () => {
    const confirmed = await getConfirmation({
      title: 'Delete Recipe',
      message: 'Are you sure you want to delete this schedule?',
      confirmText: 'Delete'
    })
    if (confirmed) await deleteItem({ id })
  }

  return (
    <Tooltip title="Delete Meal" arrow>
      <MDButton
        onClick={onClick}
        variant="outlined"
        color="dark"
        size="small"
        iconOnly
        {...props}>
        <Icon>delete</Icon>
      </MDButton>
    </Tooltip>
  )
}

export default DeleteScheduledMealButton
