import config from '@/config'
import { logout, updateAccessToken } from '@/pages/auth/userSlice'
import axios from 'axios'

let reqInterceptor
let resInterceptor
// const csrfToken = getCookie('csrftoken')

const api = axios.create({
  baseURL: config.BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

const configureApi = (instance, store) => {
  if (reqInterceptor || resInterceptor) {
    if (reqInterceptor) {
      instance.interceptors.request.eject(reqInterceptor)
    }
    if (resInterceptor) {
      instance.interceptors.response.eject(resInterceptor)
    }
  }
  reqInterceptor = instance.interceptors.request.use(
    (config) => {
      const state = store.getState()
      if (state.user.tokens.access) {
        config.headers.Authorization = `Bearer ${state.user.tokens.access}`
      }

      return config
    },
    (error) => {
      Promise.reject(error)
    }
  )

  resInterceptor = instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const state = store.getState()
      const { dispatch } = store
      const originalRequest = error.config

      if (
        error.response.status === 400 &&
        originalRequest.url.indexOf('/auth/token/refresh/') >= 0
      ) {
        return Promise.reject(error)
      }

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true
        return axios
          .post(
            `${config.BASE_URL}/auth/token/refresh/`,
            {
              refresh: state.user.tokens.refresh
            },
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              }
            }
          )
          .then((res) => {
            if (res.status === 200) {
              // 1) put token to Store
              dispatch(updateAccessToken(res.data.access))

              // 2) Change Authorization header
              instance.defaults.headers.common.Authorization = `Bearer ${res.data.access}`

              // 3) return originalRequest object with Axios.
              return instance(originalRequest)
            }
          })
          .catch((e) => {
            if (e.response.status === 401) {
              dispatch(logout())
            }
          })
      }

      // return Error object with Promise
      return Promise.reject(error)
    }
  )
}

export default api
export { configureApi }
