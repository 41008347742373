import React from "react"

import { Route, Routes } from "react-router-dom"

import NutrientList from "./nutrientList"

export default function () {
  return (
    <Routes>
      {/* <Route path="/create" element={<NutrientEdit />} />
      <Route path="/edit/:nutId" element={<NutrientEdit />} /> */}
      <Route path="/" element={<NutrientList />} />
    </Routes>
  )
}
