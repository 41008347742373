import { useContext } from 'react'
import { ConfirmationDialogContext } from './ConfirmationDialog'

const useConfirmationDialog = () => {
  const { openDialog } = useContext(ConfirmationDialogContext)

  const getConfirmation = ({ ...options }) =>
    new Promise((resolve) => {
      openDialog({ actionCallback: resolve, ...options })
    })

  return { getConfirmation }
}

function withConfirmationDialog(Component) {
  return function WrapperComponent(props) {
    return (
      <ConfirmationDialogContext.Consumer>
        {(state) => <Component {...props} context={state} />}
      </ConfirmationDialogContext.Consumer>
    )
  }
}

export { useConfirmationDialog, withConfirmationDialog }
