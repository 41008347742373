import FormikTextField from '@/components/Formik/FormikTextField'
import LoadingButton from '@/components/LoadingButton'
import MDBox from '@/components/MDBox'
import { Grid, Icon } from '@mui/material'
import { Formik } from 'formik'
import { toast } from 'react-toastify'
import useIngredientQuery from '../query/ingredients'
import FoodGroupInput from '../../foodGroups/inputs/FoodGroupInput'
import FormikTagsField from '@/components/Formik/FormikTagsField'

function IngredientForm({ id, onSuccess, onCreate }) {
  const { getIngredient, createIngredient, updateIngredient } =
    useIngredientQuery()
  const { data } = getIngredient({ id })
  const { mutateAsync: create } = createIngredient()
  const { mutateAsync: update } = updateIngredient()

  const initialValues = {
    name: data?.data?.name || '',
    food_group: data?.data?.food_group || '',
    scientific_name: data?.data?.scientific_name || '',
    tags: data?.data?.tags || []
  }

  const onSubmit = async (values, { setErrors }) => {
    try {
      let res
      if (id) {
        res = await update({ id, data: values })
        toast.success('Ingredient updated')
      } else {
        res = await create({ data: values })
        toast.success('Ingredient created')
        onCreate?.(res.data)
      }
      onSuccess?.(res.data)
    } catch (error) {
      setErrors(error?.response?.data)
      toast.success('Failed to create ingredient')
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize>
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <MDBox>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormikTextField name="name" label="Name" fullWidth />
              </Grid>

              <Grid item xs={12}>
                <FormikTagsField name="tags" label="Tags" fullWidth />
              </Grid>

              <Grid item xs={12}>
                <FormikTextField
                  name="scientific_name"
                  label="Scientific Name"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <FoodGroupInput name="food_group" label="Food Group" />
              </Grid>
              <Grid item xs={12} display="flex">
                <LoadingButton
                  sx={{ ml: 'auto' }}
                  loading={isSubmitting}
                  type="submit"
                  // loadingPosition="start"
                  startIcon={<Icon>save</Icon>}>
                  {data ? 'Update Food' : 'Create Food'}
                </LoadingButton>
              </Grid>
            </Grid>
          </MDBox>
        </form>
      )}
    </Formik>
  )
}

export default IngredientForm
