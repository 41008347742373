/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

// @mui material components
import { ThemeProvider } from '@mui/material/styles'

// Material Dashboard 2 PRO React components

// Material Dashboard 2 PRO React examples

// Material Dashboard 2 PRO React themes

// Material Dashboard 2 PRO React Dark Mode themes

// RTL plugins

// Material Dashboard 2 PRO React routes
import routes from './routes'
import authRoutes from './routes.auth'

// Material Dashboard 2 PRO React contexts
import theme from '@/assets/theme'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import themeDark from './assets/theme-dark'
import { setMiniSidenav, useMaterialUIController } from './context'
import Sidenav from './examples/Sidenav'

// Images
import brandDark from '@/assets/images/logo-ct-dark.png'
import brandWhite from '@/assets/images/logo-ct.png'
import { CssBaseline } from '@mui/material'
import 'assets/css/toast.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ConfirmationDialogProvider } from './components/Confirmation/ConfirmationDialog'
// import { ToastContainer, toast } from "react-toastify"

export default function App() {
  const isLoggedIn = useSelector((s) => s.user.isLoggedIn)

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse)
      }

      if (route.route) {
        return (
          <Route
            // exact={!route.hasSubRoutes}
            path={route.path || route.route}
            element={route.component}
            key={route.key}
          />
        )
      }

      return null
    })

  const [controller, dispatch] = useMaterialUIController()
  const {
    miniSidenav,
    direction,
    layout,

    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode
  } = controller
  const [onMouseEnter, setOnMouseEnter] = useState(false)

  const { pathname } = useLocation()

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false)
      setOnMouseEnter(true)
    }
  }

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true)
      setOnMouseEnter(false)
    }
  }

  // // Change the openConfigurator state
  // const handleConfiguratorOpen = () =>
  //   setOpenConfigurator(dispatch, !openConfigurator)

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction)
  }, [direction])

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
  }, [pathname])

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <ConfirmationDialogProvider>
        <CssBaseline />
        {isLoggedIn && layout === 'dashboard' && (
          <Sidenav
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                ? brandDark
                : brandWhite
            }
            brandName="The Diet App"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        )}
        <Routes>
          {getRoutes(isLoggedIn ? routes : authRoutes)}

          <Route
            path="*"
            element={
              <Navigate to={isLoggedIn ? '/data/ingredient' : '/login/'} />
            }
          />
        </Routes>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </ConfirmationDialogProvider>
    </ThemeProvider>
  )
}
