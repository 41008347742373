import { useConfirmationDialog } from '@/components/Confirmation/hooks'
import TableRowButton from '@/components/Table/TableRowButton'

import { Icon } from '@mui/material'
import useRecipeQuery from '@/features/recipe/query/recipe'

function DeleteRecipeButton({ id }) {
  const { deleteRecipe } = useRecipeQuery()
  const { mutateAsync: deleteItem, isLoading } = deleteRecipe()
  const { getConfirmation } = useConfirmationDialog()

  const onClick = async () => {
    const confirmed = await getConfirmation({
      title: 'Delete Recipe',
      message: 'Are you sure you want to delete this RecipeDetail.jsx?',
      confirmText: 'Delete'
    })
    if (confirmed) await deleteItem({ id })
  }

  return (
    <TableRowButton
      onClick={onClick}
      disabled={isLoading}
      tooltip="Delete"
      variant="gradient"
      color="light">
      <Icon>delete</Icon>
    </TableRowButton>
  )
}

export default DeleteRecipeButton
