import PageHeader from '@/components/Layout/PageHeader'
import DashboardLayout from '@/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from '@/examples/Navbars/DashboardNavbar'
import { Card } from '@mui/material'
import AddNutrientButton from '@/features/nutrients/buttons/AddNutrientButton'
import NutrientTable from '@/features/nutrients/NutrientTable'

function NutrientList() {
  return (
    <DashboardLayout>
      <DashboardNavbar
        title="Nutrients"
        route={[{ label: 'Nutrients', path: 'data/nutrients' }]}
      />
      <PageHeader
        title="Nutrients List"
        description="Use this page to add, update and delete food nutrients"
        actions={() => <AddNutrientButton />}
      />
      <Card>
        <NutrientTable />
      </Card>
    </DashboardLayout>
  )
}

export default NutrientList
