import { useMemo } from 'react'
import MDTypography from '../../../components/MDTypography'

import DataTable from '../../../examples/Tables/DataTable'
import useCompositionQuery from '@/features/composition/query/composition'
import math from 'lodash'

function RecipeCompositionTable({ recipeId }) {
  const { getCompositions } = useCompositionQuery()

  const { data, isLoading } = getCompositions({
    recipe: recipeId,
    expand: 'nutrient'
  })

  const columns = useMemo(
    () => [
      {
        Header: 'name',
        width: '70%',
        accessor: 'nutrient.name',
        Cell: ({
          row: {
            original: { nutrient }
          }
        }) => (
          <MDTypography
            variant="body2"
            style={{
              fontWeight: nutrient.nutrient_type === 'macro' ? 700 : 400
            }}>
            {nutrient.name}
          </MDTypography>
        )
      },
      {
        Header: 'Measure',
        width: '20%',
        Cell: ({
          row: {
            original: { quantity, nutrient }
          }
        }) => (
          <MDTypography
            variant="body2"
            style={{
              fontWeight: nutrient.nutrient_type === 'macro' ? 700 : 400
            }}>
            {math.round(quantity, 2)}
            {nutrient.unit}
          </MDTypography>
        )
      }
      // {
      //   Header: 'Actions',
      //   // accessor: "name",
      //   width: '10%',
      //   Cell: ({
      //     row: {
      //       original: { id, ingredient }
      //     }
      //   }) => (
      //     <>
      //       <EditCompositionTableButton
      //         id={id}
      //         ingredientId={ingredient}
      //         sx={{ mr: 1 }}
      //       />
      //       <DeleteCompositionButton id={id} />
      //     </>
      //   )
      // }
    ],
    []
  )

  return (
    <>
      {data && (
        <DataTable
          canSearch
          table={{
            columns: columns,
            rows: data?.data?.results
          }}
        />
      )}
    </>
  )
}

export default RecipeCompositionTable
