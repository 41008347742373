import FormikAutocomplete from '@/components/Formik/FormikAutocomplete'
import useIngredientsQuery from '@/features/ingredients/query/ingredients'
import { MenuItem } from '@mui/material'

function IngredientSelect({ name, label = 'Ingredient' }) {
  const { getIngredients } = useIngredientsQuery()
  const { data: ingredients } = getIngredients()

  return (
    <FormikAutocomplete
      fullWidth
      name={name}
      label={label}
      getOptionLabel={(option) =>
        ingredients?.data?.results.filter((x) => x.id === option)?.[0]?.name ||
        ''
      }
      renderOption={(props, option) => (
        <MenuItem value={option} {...props} key={option}>
          {ingredients?.data?.results.filter((x) => x.id === option)?.[0]?.name}
        </MenuItem>
      )}
      options={ingredients?.data?.results.map((x) => x.id) || []}
    />
  )
}

export default IngredientSelect
