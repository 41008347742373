import ListLoader from '@/components/List/ListLoader'
import MDTypography from '@/components/MDTypography'
import DashboardLayout from '@/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from '@/examples/Navbars/DashboardNavbar'
import AddCompositionButton from '@/features/composition/buttons/AddCompositionButton'
import IngredientCompositionTable from '@/features/ingredients/lists/IngredientCompTable'
import useIngredientQuery from '@/features/ingredients/query/ingredients'

import { Box, Card } from '@mui/material'
import { useParams } from 'react-router-dom'
import IngredientCard from '@/features/ingredients/detail/IngredientCard'

function IngredientDetail() {
  const { id } = useParams()
  const { getIngredient } = useIngredientQuery()
  const { data, isLoading } = getIngredient({ id })

  return (
    <DashboardLayout>
      <DashboardNavbar
        title={data ? data?.data?.name : 'Create Ingredient'}
        route={[
          { label: 'Ingredients', path: 'data/ingredient' },
          { label: 'Edit Ingredient' }
        ]}
      />
      {isLoading && id && <ListLoader />}
      {(!id || !isLoading) && (
        <>
          {data && <IngredientCard ingredient={data?.data} sx={{ mb: 3 }} />}
          <Card>
            <Box sx={{ display: 'flex', alignItems: 'center', px: 3, py: 3 }}>
              <MDTypography variant="subtitl1" fontWeight="bold">
                Composition Table
              </MDTypography>
              <AddCompositionButton
                sx={{ ml: 'auto' }}
                size="small"
                color="info"
                disabled={isLoading || !id}
                ingredientId={id}
                foodId={data?.data?.food_item}
              />
            </Box>
            <IngredientCompositionTable
              ingredientId={id}
              disabled={isLoading || !id}
            />
          </Card>
        </>
      )}
    </DashboardLayout>
  )
}

export default IngredientDetail
