import ListLoader from '@/components/List/ListLoader'
import MDTypography from '@/components/MDTypography'
import TableRowButton from '@/components/Table/TableRowButton'
import DataTable from '@/examples/Tables/DataTable'

import { Icon } from '@mui/material'

import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import useIngredientQuery from '../query/ingredients'

function IngredientsTable() {
  const navigate = useNavigate()

  const { deleteIngredient, getIngredients } = useIngredientQuery()
  const { data, isLoading } = getIngredients()

  const columns = useMemo(
    () => [
      {
        Header: 'name',
        accessor: 'name',
        width: '90%',
        Cell: ({
          row: {
            original: { name, group }
          }
        }) => (
          <>
            <MDTypography variant="body2" fontWeight="medium">
              {name}
            </MDTypography>
            <MDTypography variant="caption">{group}</MDTypography>
          </>
        )
      },
      {
        Header: 'Actions',
        width: '10%',
        Cell: ({
          row: {
            original: { id }
          }
        }) => (
          <>
            <TableRowButton
              tooltip="Edit"
              onClick={() => navigate(`/data/ingredient/edit/${id}`)}
              sx={{ mr: 2 }}>
              <Icon>edit</Icon>
            </TableRowButton>
            <TableRowButton
              tooltip="Delete"
              onClick={() => deleteIngredient({ id })}>
              <Icon>delete</Icon>
            </TableRowButton>
          </>
        )
      }
    ],
    []
  )

  return (
    <>
      {isLoading && <ListLoader />}
      {data && (
        <DataTable
          canSearch
          table={{
            columns: columns,
            rows: data.data.results
          }}
        />
      )}
    </>
  )
}

export default IngredientsTable
