import { Route, Routes } from 'react-router-dom'
import IngredientDetail from './IngredientDetail'
import IngredientList from './IngredientList'

export default function () {
  return (
    <Routes>
      <Route path="/create" element={<IngredientDetail />} />
      <Route path="/edit/:id" element={<IngredientDetail />} />
      <Route path="/" element={<IngredientList />} />
    </Routes>
  )
}
